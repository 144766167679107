// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import R from '../../../../services/app/request.service'
import Table from '../../LocalElements/Table/Table'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import PencilDisabled from '../../../../img/icon/PencilDisabled'
import { refreshIssueCase } from '../../../../store/slices/app/controlers/updater'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import Header from './header/index'
import Filter from './filter/index'
import Tooltip from '../../../../ui/tooltip/index'

const TabKitGraph = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector(state => state.journalReducer.dataKitVerifications)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const entryId = useSelector(state => state.journalReducer.entryId)

  const handleDelete = async journalId => {
    const { status } = await R.deleteKitVerifications(journalId, companyId)
    if (status === 200) {
      dispatch(refreshIssueCase())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
      dispatch(resetState())
    }
  }

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
    },
    {
      field: 'name',
      headerName: 'Наименование, тип, заводской №',
    },
    {
      field: 'interval',
      headerName: 'Периодичность поверки, месяцы',
    },
    {
      field: 'date',
      headerName: 'Дата последней поверки',
    },
    {
      field: 'datEexpired',
      headerName: 'Дата истечения срока поверки',
    },
    {
      field: 'isVerification',
      headerName: 'Отметка о новой поверке',
      renderCell: params => {
        return params.value ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CheckCircleIcon fontSize='small' sx={{ color: '#21E1B3' }} />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CancelIcon fontSize='small' sx={{ color: '#FF5963' }} />
          </div>
        )
      },
    },
    {
      field: 'place',
      headerName: 'Поверяющая организация',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {params.row.autoGenerated ? (
              <Tooltip title='Редактирование данных о поверке доступно в разделе средства поверки'>
                <div>
                  <PencilDisabled />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Удалить запись'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(showBA(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_kit_verifications`)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Filter />
      <Header />
      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          number: item?.number ?? '-',
          name: item?.name ?? '-',
          interval: item?.interval ?? '-',
          date: item?.date ?? '-',
          datEexpired: item?.date_expired ?? '-',
          isVerification: item?.is_has_new_verification ?? '-',
          place: item?.place ?? '-',
          autoGenerated: item?.is_autogenerated ?? '-',
        }))}
        hasCheckboxSelection={false}
        noRowsText={'Записи отсутствуют'}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </div>
  )
}

export default TabKitGraph
