// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleIcon from '../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../img/icon/VisibilityToggleIcon'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'
import { Typography } from '@mui/material'
import { LabelFrame } from '../../pages/Cases/style'

const TypeMethod = () => {
  const dispatch = useDispatch()
  const typeMethodId = useSelector(state => state.casesReducer.createData.typeMethodId)

  const options = [
    { value: 'initial', label: 'Начальная поверка' },
    { value: 'periodic', label: 'Периодическая поверка' },
  ]

  const getValue = selectedOptions => {
    const selectedIds = selectedOptions.map(options => options.value)
    dispatch(
      changeData({
        value: 'typeMethodId',
        label: selectedIds,
      })
    )

    dispatch(
      changeData({
        value: 'methodPoints',
        label: [],
      })
    )

    if (!selectedOptions) {
      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        })
      )

      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        })
      )
    }
  }

  return (
    <LabelFrame>
      <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        Тип поверки
        {typeMethodId.length === 0 ? <VisibilityToggleIcon /> : <CheckCircleIcon />}
      </Typography>
      <Selector
        placeholder={'Выберите тип поверки из списка'}
        isClearable={false}
        isSearchable={false}
        isMulti={true}
        options={options}
        onChange={getValue}
        value={options.filter(option => typeMethodId.includes(option.value))}
        noOptionsMessage={() => 'Выберите методику поверки'}
      />
    </LabelFrame>
  )
}

export default TypeMethod
