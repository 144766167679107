import React from 'react'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ru'

dayjs.locale('ru')

const StyledDatePicker = styled(MuiDatePicker)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    paddingRight: '20px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d7ddea',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d7ddea',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#0084e2',
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: '12px',
  },
  '& .MuiPickersDay-day': {
    borderRadius: '12px',
  },
  '& .MuiPickersCalendar-transitionContainer': {
    borderRadius: '12px',
  },
  '& .MuiSvgIcon-root': {
    color: '#0084e2',
  },
}))

const DatePicker = props => {
  const {
    selectedDate,
    dateChange,
    minDate,
    maxDate,
    label,
    format = 'default',
    isDisabled = false,
    disableFuture = false,
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <StyledDatePicker
        value={selectedDate ? dayjs(selectedDate) : null}
        onChange={dateChange}
        format={format === 'default' ? 'DD-MM-YYYY' : format === 'year' ? 'YYYY' : 'DD-MM-YYYY'}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
        label={label || ''}
        views={format === 'default' ? ['day', 'month', 'year'] : format === 'year' ? ['year'] : ''}
        disabled={isDisabled}
        slotProps={{
          textField: {
            inputProps: {
              readOnly: false,
            },
            InputLabelProps: {
              shrink: true,
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
