// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery, Fab } from '@mui/material'
import R from '../../../../../services/app/request.service'
import Csv from '../../../../../img/icon/CsvIcon'
import Excel from '../../../../../img/icon/Excel'
import Pdf from '../../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../../img/icon/ArchiveIcon'
import FilterIcon from '../../../../../img/icon/FilterIcon'
import { setOpen as setOpenRightContainer } from '../../../../../store/slices/app/comps/rightContainer'
import CloseIcon from '@mui/icons-material/Close'
import {
  refreshIssueCase,
  refreshArchives,
} from '../../../../../store/slices/app/controlers/updater'
import SearchQuery from '../../../LocalElements/Search/SearchQuery'
import { Container, CountLabel, Filter, IconFrame } from '../../style'
import theme from '../../../../../utils/theme.ts'
import Button from '../../../../../ui/button/index'
import Tooltip from '../../../../../ui/tooltip/index'

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'
import {
  setSearchQuery,
  resetKitVerifications,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../../store/slices/app/views/journals'
import useExcelCsvPdf from '../../../../hooks/useExcelCsv'
import { archiveTitle, archiveMessage, ModalContent } from '../../archiveModal/archiveModal'

const Header = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { handleDownload } = useExcelCsvPdf()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))

  const searchQuery = useSelector(state => state.journalReducer.searchQuery)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const journalId = useSelector(state => state.journalReducer.journalId)
  const entryId = useSelector(state => state.journalReducer.entryId)

  const isReturned = useSelector(state => state.journalReducer.kitVerifications.isReturned)
  const completenessStatus = useSelector(
    state => state.journalReducer.kitVerifications.completenessStatus
  )
  const createdByRole = useSelector(state => state.journalReducer.kitVerifications.createdByRole)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateFrom
  )
  const verificationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateTo
  )
  const createdAtFrom = useSelector(state => state.journalReducer.kitVerifications.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.kitVerifications.createdAtTo)
  const expirationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateFrom
  )
  const expirationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateTo
  )

  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (isReturned) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++
    if (createdAtFrom) filtersCount++
    if (createdAtTo) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (expirationDateFrom) filtersCount++
    if (expirationDateTo) filtersCount++
    if (
      !isReturned &&
      !completenessStatus &&
      !createdByRole &&
      !createdAtFrom &&
      !createdAtTo &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !expirationDateFrom &&
      !expirationDateTo
    )
      filtersCount = 0
    return filtersCount
  }, [
    isReturned,
    completenessStatus,
    createdByRole,
    createdAtFrom,
    createdAtTo,
    verificationDateFrom,
    verificationDateTo,
    expirationDateFrom,
    expirationDateTo,
  ])

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleResetFilter = () => {
    dispatch(resetKitVerifications())
  }

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_kit_verifications')
  }

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveKitVerifications(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshIssueCase())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`
    )

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive-selected.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (archiveDateFromBySettings && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFromBySettings, archiveDateTo])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={archiveDateFromBySettings}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  return (
    <Container sx={{ paddingRight: count > 0 ? '50px' : '0px' }}>
      {!entryId && <Button onClick={handleAddClick} label='Добавить запись' />}

      <div style={{ width: '100%' }}>
        <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
      </div>

      <IconFrame>
        {count > 0 ? (
          <Tooltip title='Для скачивания PDF отключите фильтры'>
            <div style={{ cursor: 'default', opacity: '40%' }}>
              <Pdf />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title='Скачать в формате PDF'>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                !entryId && handleDownload('pdf')
                !!entryId && handleDownloadSelectionPdf()
              }}
            >
              <Pdf />
            </div>
          </Tooltip>
        )}
        {!entryId && (
          <Tooltip title='Скачать документ в Csv'>
            <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('csv')}>
              <Csv />
            </div>
          </Tooltip>
        )}
        {!entryId && (
          <Tooltip title='Скачать документ в Excel'>
            <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('excel')}>
              <Excel />
            </div>
          </Tooltip>
        )}
        {!entryId && (
          <React.Fragment>
            {count > 0 ? (
              <Tooltip title='Для архивации документа отключите фильтры'>
                <div style={{ cursor: 'default', opacity: '40%' }}>
                  <ArchiveIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Архивировать документ'>
                <div style={{ cursor: 'pointer' }}>
                  <ArchiveIcon
                    onClick={() => {
                      dispatch(setTitle('Архивация документа'))
                      dispatch(setChildren(modalContent))
                      dispatch(setBA('Архивировать'))
                      dispatch(setBD('Отменить'))
                      dispatch(showBD(true))
                      dispatch(setConfirmAction(handleArchiveClick))
                      dispatch(setOpen(true))
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        )}
      </IconFrame>

      <Filter sx={{ width: isMobile ? '220px' : '100%' }}>
        <Button
          onClick={() => {
            dispatch(setOpenRightContainer(true))
          }}
          startIcon={<FilterIcon />}
          label='Фильтры'
          color='secondary'
        />

        <CountLabel sx={{ opacity: count > 0 ? 1 : 0 }}>{count}</CountLabel>
      </Filter>
      {count > 0 && (
        <Fab
          onClick={handleResetFilter}
          size='small'
          style={{
            position: 'absolute',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
            backgroundColor: '#E74953',
            left: '100%',
            marginLeft: '-48px',
            transform: 'scale(0.7)',
          }}
        >
          <CloseIcon style={{ color: '#f8f9fc' }} />
        </Fab>
      )}
    </Container>
  )
}

export default Header
