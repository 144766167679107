import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
  dataVerifications: [],
  dataTickets: [],
  dataPersonalMetrolog: [],
  dataKitVerifications: [],
  dataConditions: [],
  dataComplect: [],
  dataReceived: [],
  dataArchive: [],
  dataIssueCase: [],
  dataKitPassports: [],

  isLoadingVerifications: true,
  isLoadingTickets: true,
  isLoadingPersonalMetrolog: true,
  isLoadingConditions: true,
  isLoadingComplect: true,
  isLoadingReceived: true,
  isLoadingArchive: true,
  isLoadingIssueCase: true,

  selectedVerifications: null,

  metaVerifications: null | undefined,
  metaTickets: null | undefined,
  metaPersonalMetrolog: null | undefined,
  metaConditions: null | undefined,
  metaComplect: null | undefined,
  metaReceived: null | undefined,
  metaArchive: null | undefined,

  selectedKitPassport: [],

  journalId: '',
  entryId: '',
  masterArshine: '',
  journalIdFilter: '',
  dateFrom: '',
  dateUntil: '',
  createDateFrom: '',
  createDateUntil: '',
  expiredAt: '',
  searchQuery: '',
  complete: '',
  creator: '',
  totalAccepted: '',
  typeMethod: '',
  documents: [],
  archiveFromPerson: '',
  archiveFromDate: '',
  archiveToDate: '',
  viewVariantMain: 0,

  archiveFrom: null,
  archiveTo: null,

  archived: {
    accountNumber: '',
    createdByName: '',
    issueDate: '',
    returnDate: '',
    deletedDate: '',
    selectedRows: [],
  },

  received: {
    measurementId: '',
    receivedDate: '',
    receivedByName: '',
    issuedDate: '',
    customerArshinName: '',
    workContent: '',
    fifNumber: '',
    nameTypeSi: '',
    meterFactoryNumber: '',
    verificationDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    protocolNumber: '',
    arshinMasterName: '',
    totalAccepted: true,
    arshinId: '',
    issuedByName: '',
    typeMethod: '',
  },

  tickets: {
    searchQuery: '',
    measurementId: '',
    arshinName: '',
    createdAtFrom: '',
    createdAtTo: '',
    isChecked: '',
    verificationDateFrom: '',
    verificationDateTo: '',
    completenessStatus: '',
    createdByRole: '',

    measurement: '',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    createdBy: '',
    customerName: '',
    customerPhone: '',
    customerAddress: '',
    verificationDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    isCheckedMark: '',
    arshinFullName: '',
    numberAgreement: '',
    comment: '',
  },

  personal: {
    searchQuery: '',
    measurementId: '',
    createdAtFrom: '',
    createdAtTo: '',
    verificationDateFrom: '',
    verificationDateTo: '',
    completenessStatus: '',
    createdByRole: '',

    measurement: '',
    numSi: '',
    nameSi: '',
    typeSi: '',
    numFactory: '',
    arshinFullName: '',
    verificationDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    numNotification: '',
    numProtocol: '',
  },

  kitVerifications: {
    isReturned: '',
    completenessStatus: '',
    createdByRole: '',
    createdAtFrom: '',
    createdAtTo: '',
    verificationDateFrom: '',
    verificationDateTo: '',
    expirationDateFrom: '',
    expirationDateTo: '',
  },

  kitVerificationsAdd: {
    name: '',
    verificationDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    interval: '',
    place: '',
    isHasNewVerification: '',
  },

  kitPassportsFilter: {
    systemExistingStatus: '',
    formedDateFrom: '',
    formedDateTo: '',
    responsiblePassportName: '',
  },

  kitPassports: {
    responsiblePassportName: '',
    kitGroup: '',
    formedDate: '',
    accountingNumber: '',
    name: '',
    type: '',
    registerNumber: '',
    manufacturerName: '',
    releaseYear: '',
    manufacturerNumber: '',
    measRange: '',
    reprRange: '',
    error: '',
    kitAccuracyClassId: '',
    deviations: '',
    softwareInfo: '',
    componentInfo: '',
    receiptDate: '',
    commissionDate: '',
    inventoryNumber: '',
    location: '',
  },

  createConditions: {
    temp: '',
    humidity: '',
    pressure: '',
    conditionDate: '',
  },

  issueCaseFilter: {
    measurementId: '',
    innerName: '',
    issuedDateFrom: '',
    issuedDateTo: '',
    receivedByName: '',
    isReturned: '',
    verificationDateFrom: '',
    verificationDateTo: '',
    completenessStatus: '',
    createdByRole: '',
  },

  issueCase: {
    measurement: '',
    caseNum: '',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    issuedName: '',
    receivedName: '',
    returned: false,
    issueDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    receivedDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
}

export const journalReducer = createSlice({
  name: 'journalReducer',
  initialState,
  reducers: {
    // ----------------------------------------------------------------
    // изменения объектов-хранилищ списков журналов
    // ----------------------------------------------------------------

    setDataVerifications: (state, action) => {
      state.dataVerifications = action.payload
    },
    setMetaVerifications: (state, action) => {
      state.metaVerifications = action.payload
    },

    setDataTickets: (state, action) => {
      state.dataTickets = action.payload
    },
    setMetaTickets: (state, action) => {
      state.metaTickets = action.payload
    },

    setDataPersonalMetrolog: (state, action) => {
      state.dataPersonalMetrolog = action.payload
    },
    setMetaPersonalMetrolog: (state, action) => {
      state.metaPersonalMetrolog = action.payload
    },

    setDataKitVerifications: (state, action) => {
      state.dataKitVerifications = action.payload
    },

    setDataConditions: (state, action) => {
      state.dataConditions = action.payload
    },
    setMetaConditions: (state, action) => {
      state.metaConditions = action.payload
    },

    setDataComplect: (state, action) => {
      state.dataComplect = action.payload
    },
    setMetaComplect: (state, action) => {
      state.metaComplect = action.payload
    },

    setDataReceived: (state, action) => {
      state.dataReceived = action.payload
    },
    setMetaReceived: (state, action) => {
      state.metaReceived = action.payload
    },

    setDataIssueCase: (state, action) => {
      state.dataIssueCase = action.payload
    },

    setDataKitPassports: (state, action) => {
      state.dataKitPassports = action.payload
    },

    setDataArchive: (state, action) => {
      state.dataArchive = action.payload
    },
    setMetaArchive: (state, action) => {
      state.metaArchive = action.payload
    },

    setSelectedKitPassport: (state, action) => {
      state.selectedKitPassport = action.payload
    },

    // ----------------------------------------------------------------
    // ----------------------------------------------------------------

    // ----------------------------------------------------------------
    // изменения объектов-хранилищ данных конкретных журналов и записией
    // ----------------------------------------------------------------

    changeArchived: (state, action) => {
      state.archived[action.payload.value] = action.payload.label
    },
    resetArchived: state => {
      state.archived = initialState.archived
    },

    changeReceived: (state, action) => {
      state.received[action.payload.value] = action.payload.label
    },
    resetReceived: state => {
      state.received = initialState.received
    },

    changeTickets: (state, action) => {
      state.tickets[action.payload.value] = action.payload.label
    },
    resetTickets: state => {
      state.tickets = initialState.tickets
    },

    changePersonal: (state, action) => {
      state.personal[action.payload.value] = action.payload.label
    },
    resetPersonal: state => {
      state.personal = initialState.personal
    },

    changeComplect: (state, action) => {
      state.createComplect[action.payload.value] = action.payload.label
    },
    resetCreateComplect: state => {
      state.createComplect = initialState.createComplect
    },

    changeKitVerifications: (state, action) => {
      state.kitVerifications[action.payload.value] = action.payload.label
    },
    resetKitVerifications: state => {
      state.kitVerifications = initialState.kitVerifications
    },

    changeKitVerificationsAdd: (state, action) => {
      state.kitVerificationsAdd[action.payload.value] = action.payload.label
    },
    resetKitVerificationsAdd: state => {
      state.kitVerificationsAdd = initialState.kitVerificationsAdd
    },

    changeKitPassport: (state, action) => {
      state.kitPassports[action.payload.value] = action.payload.label
    },
    resetKitPassport: state => {
      state.kitPassports = initialState.kitPassports
    },

    changeConditions: (state, action) => {
      state.createConditions[action.payload.value] = action.payload.label
    },
    resetCreateConditions: state => {
      state.createConditions = initialState.createConditions
    },

    changeIssueCase: (state, action) => {
      state.issueCase[action.payload.value] = action.payload.label
    },
    changeIssueCaseFilter: (state, action) => {
      state.issueCaseFilter[action.payload.value] = action.payload.label
    },
    resetIssueCase: state => {
      state.issueCase = initialState.issueCase
    },
    resetIssueCaseFilter: state => {
      state.issueCaseFilter = initialState.issueCaseFilter
    },

    changeKitPassportsFilter: (state, action) => {
      state.kitPassportsFilter[action.payload.value] = action.payload.label
    },
    resetKitPassportsFilter: state => {
      state.kitPassportsFilter = initialState.kitPassportsFilter
    },

    // ----------------------------------------------------------------
    // ----------------------------------------------------------------

    setIsLoadingVerifications: (state, action) => {
      state.isLoadingVerifications = action.payload
    },
    setIsLoadingTickets: (state, action) => {
      state.isLoadingTickets = action.payload
    },
    setIsLoadingPersonalMetrolog: (state, action) => {
      state.isLoadingPersonalMetrolog = action.payload
    },
    setIsLoadingConditions: (state, action) => {
      state.isLoadingConditions = action.payload
    },
    setIsLoadingComplect: (state, action) => {
      state.isLoadingComplect = action.payload
    },
    setIsLoadingReceived: (state, action) => {
      state.isLoadingReceived = action.payload
    },
    setIsLoadingIssueCase: (state, action) => {
      state.isLoadingIssueCase = action.payload
    },
    setIsLoadingArchive: (state, action) => {
      state.isLoadingArchive = action.payload
    },

    setJournalId: (state, action) => {
      state.journalId = action.payload
    },
    setMasterArshin: (state, action) => {
      state.masterArshine = action.payload
    },
    setJournalIdFilter: (state, action) => {
      state.journalIdFilter = action.payload
    },
    setEntryId: (state, action) => {
      state.entryId = action.payload
    },

    setDateFrom: (state, action) => {
      state.dateFrom = action.payload
    },
    setDateUntil: (state, action) => {
      state.dateUntil = action.payload
    },
    setCreateDateFrom: (state, action) => {
      state.createDateFrom = action.payload
    },
    setCreateDateUntil: (state, action) => {
      state.createDateUntil = action.payload
    },
    setViewVariantMain: (state, action) => {
      state.viewVariantMain = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setComplete: (state, action) => {
      state.complete = action.payload
    },
    setCreator: (state, action) => {
      state.creator = action.payload
    },
    setTotalAccepted: (state, action) => {
      state.totalAccepted = action.payload
    },
    setTypeMethod: (state, action) => {
      state.typeMethod = action.payload
    },
    setDocuments: (state, action) => {
      state.documents = action.payload
    },
    setArchiveFromPerson: (state, action) => {
      state.archiveFromPerson = action.payload
    },
    setArchiveFromDate: (state, action) => {
      state.archiveFromDate = action.payload
    },
    setArchiveToDate: (state, action) => {
      state.archiveToDate = action.payload
    },

    setArchiveFrom: (state, action) => {
      state.archiveFrom = action.payload
    },
    setArchiveTo: (state, action) => {
      state.archiveTo = action.payload
    },
    resetArchiveFrom: state => {
      state.archiveFrom = initialState.archiveFrom
    },
    resetArchiveTo: state => {
      state.archiveTo = initialState.archiveTo
    },
  },
})

export const {
  changeArchived,
  resetArchived,

  changeReceived,
  resetReceived,

  changeTickets,
  resetTickets,

  changePersonal,
  resetPersonal,

  changeKitVerifications,
  resetKitVerifications,

  changeKitVerificationsAdd,
  resetKitVerificationsAdd,

  changeKitPassport,
  resetKitPassport,

  changeConditions,
  resetCreateConditions,

  changeIssueCase,
  changeIssueCaseFilter,
  resetIssueCase,
  resetIssueCaseFilter,

  changeKitPassportsFilter,
  resetKitPassportsFilter,

  changeComplect,

  setDataVerifications,
  setMetaVerifications,

  setDataTickets,
  setMetaTickets,

  setDataPersonalMetrolog,
  setMetaPersonalMetrolog,

  setDataKitVerifications,

  setDataConditions,
  setMetaConditions,

  setDataComplect,
  setMetaComplect,

  setDataReceived,
  setMetaReceived,

  setDataIssueCase,

  setDataKitPassports,

  setDataArchive,
  setMetaArchive,

  setSelectedKitPassport,

  resetCreateTickets,
  resetCreateComplect,
  resetArchiveFrom,
  resetArchiveTo,

  setIsLoadingVerifications,
  setIsLoadingTickets,
  setIsLoadingPersonalMetrolog,
  setIsLoadingConditions,
  setIsLoadingComplect,
  setIsLoadingReceived,
  setIsLoadingIssueCase,
  setIsLoadingArchive,

  setJournalId,
  setMasterArshin,
  setJournalIdFilter,
  setEntryId,

  setDateFrom,
  setDateUntil,
  setCreateDateFrom,
  setCreateDateUntil,

  setViewVariantMain,
  setSearchQuery,
  setComplete,
  setCreator,
  setTotalAccepted,
  setTypeMethod,
  setDocuments,
  setArchiveFromPerson,
  setArchiveFromDate,
  setArchiveToDate,

  setArchiveFrom,
  setArchiveTo,
} = journalReducer.actions
export default journalReducer.reducer
