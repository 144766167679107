// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import css from '../styles.css.js'
import LayoutBuilder from './LayoutBuilder.ts'

const ViewProtocolComponent = () => {
  const { ViewProtocol } = css

  const view = useRef()
  const viewData = useSelector(state => state.showProtocolReducer.data)

  useEffect(() => {
    false && console.log(viewData)

    if (viewData) {
      let kits = viewData.archivedCase.archivedKits
        .map(item => {
          return `${item.device.name}. ${item.device_manufacturer_number} * ${item.device_sign} * ${item.reg_number}. Переход - ${item.device_link}`
        })
        .join('<br/>')

      // ----------------------------------------------------------------
      // начальная реализация - только для воды
      // потом постараться учесть инициализацию класса для газа
      // ----------------------------------------------------------------

      const B = new LayoutBuilder(
        'water',
        viewData.archivedCase.company
          ? viewData.archivedCase.company.name
          : viewData.archivedCase.company.name,
        viewData.archivedCase.company
          ? viewData.archivedCase.company.accreditation_unique_id
          : viewData.archivedCase.company.accreditation_unique_id,
        viewData.archivedCase.company
          ? viewData.archivedCase.company.address
          : viewData.archivedCase.company.address
      )

      if (view.current)
        view.current.innerHTML = `
        ${B.renderCompanyName()}
        ${B.renderCompanyNumber()}
        ${B.renderCompanyAddress()}
        ${B.renderTwoColumnsTable(
          `Протокол периодической поверки средства измерения № ${viewData.id} от ${
            viewData.created_at.full.split(' ')[0]
          }`,
          null,
          [
            viewData.verificationTyp ? viewData.verificationType.name : 'Вид проверки не обнаружен',
            `${viewData.meter.name_si}, ${viewData.details.modification}${
              viewData.meter.type_si ? `, ${viewData.meter.type_si}` : ''
            }`,
            viewData.details.meter_factory_year,
            viewData.meter.fif_number,
            viewData.details.meter_factory_number,
            viewData.customer_arshin_name,
            viewData.customer_address,
            viewData.details.diameter,
            viewData.details.q_min,
            viewData.details.q_t,
            viewData.details.q_max,
            `± ${viewData.details.q_min_limit}`,
            `± ${viewData.details.q_max_limit}`,
            viewData.point.name,
          ]
        )}
        ${B.renderContentTitle('Средства проведения поверки')}
        ${B.renderContentText(kits)}
        ${B.renderThreeColumnsTable(
          [
            'Условия поверки',
            'Температура окр. воздуха, ºС',
            'Атмосферное давление, КПа',
            'Относительная влажность, %',
            'Температура воды, ºС',
          ],
          [
            'В начале поверки',
            viewData.protocolCondition[0].temperature_environment,
            viewData.protocolCondition[0].atmospheric_pressure,
            viewData.protocolCondition[0].relative_humidity,
            viewData.protocolCondition[0].water_temp,
          ],
          [
            'В конце поверки',
            viewData.protocolCondition[1].temperature_environment,
            viewData.protocolCondition[1].atmospheric_pressure,
            viewData.protocolCondition[1].relative_humidity,
            viewData.protocolCondition[1].water_temp,
          ]
        )}
        ${B.renderContentTitle('Результаты проведения поверки')}
        ${B.renderContentTitle('Внешний осмотр прибора')}
        ${B.renderContentText(
          'Возможность визуально считывать показания счетчика имеется, целостность счетного механизма счетчика не нарушена, механические повреждения на корпусе счетчика отсутствуют, комплектность счетчика соответствует описанию типа, знак утверждения типа нанесен на счетчик в установленном месте.'
        )}
        ${B.renderContentTitle('Опробование')}
        ${B.renderContentTitle(
          'Проверка герметичности соединений счетчика с трубопроводом и эталоном'
        )}
        ${B.renderContentText(
          'При открытом запорном устройстве перед счётчиком и закрытом запорном устройстве после эталона в течение пяти минут в местах соединений и на корпусе счётчика и эталона каплепадений или течи воды не наблюдается.'
        )}
        ${B.renderContentTitle('Определение относительной погрешности')}
        ${
          viewData.point.contains_optical_sensor
            ? B.renderOpticalColumnsTable({
                qdata: [
                  viewData.details.q_min,
                  (viewData.details.q_t * 1.1).toFixed(4),
                  viewData.details.q_actual,
                ],
                qi: [
                  Object.values(viewData.protocolIndication[0])[2],
                  Object.values(viewData.protocolIndication[1])[2],
                  Object.values(viewData.protocolIndication[2])[2],
                ],
                vui: [
                  Object.values(viewData.protocolIndication[0])[4],
                  Object.values(viewData.protocolIndication[1])[4],
                  Object.values(viewData.protocolIndication[2])[4],
                ],
                vyi: [
                  Object.values(viewData.protocolIndication[0])[5],
                  Object.values(viewData.protocolIndication[1])[5],
                  Object.values(viewData.protocolIndication[2])[5],
                ],
                byi: [
                  Object.values(viewData.protocolIndication[0])[6],
                  Object.values(viewData.protocolIndication[1])[6],
                  Object.values(viewData.protocolIndication[2])[6],
                ],
                impulse: [
                  Object.values(viewData.protocolIndication[0])[3],
                  Object.values(viewData.protocolIndication[1])[3],
                  Object.values(viewData.protocolIndication[2])[3],
                ],
                koeff: [
                  Object.values(viewData.protocolIndication[0])[1],
                  Object.values(viewData.protocolIndication[1])[1],
                  Object.values(viewData.protocolIndication[2])[1],
                ],
              })
            : B.renderNoOpticalColumnsTable({
                qdata: [
                  viewData.details.q_min,
                  (viewData.details.q_t * 1.1).toFixed(4),
                  viewData.details.q_actual,
                ],
                qi: Object.values(viewData.protocolIndication[0])
                  .slice(1, 4)
                  .concat(
                    Object.values(viewData.protocolIndication[1]).slice(1, 4),
                    Object.values(viewData.protocolIndication[2]).slice(1, 4)
                  ),
                vui: Object.values(viewData.protocolIndication[0])
                  .slice(10, 13)
                  .concat(
                    Object.values(viewData.protocolIndication[1]).slice(10, 13),
                    Object.values(viewData.protocolIndication[2]).slice(10, 13)
                  ),
                vyi: Object.values(viewData.protocolIndication[0])
                  .slice(13, 16)
                  .concat(
                    Object.values(viewData.protocolIndication[1]).slice(13, 16),
                    Object.values(viewData.protocolIndication[2]).slice(13, 16)
                  ),
                byi: Object.values(viewData.protocolIndication[0])
                  .slice(16)
                  .concat(
                    Object.values(viewData.protocolIndication[1]).slice(16),
                    Object.values(viewData.protocolIndication[2]).slice(16)
                  ),
                start: Object.values(viewData.protocolIndication[0])
                  .slice(4, 7)
                  .concat(
                    Object.values(viewData.protocolIndication[1]).slice(4, 7),
                    Object.values(viewData.protocolIndication[2]).slice(4, 7)
                  ),
                finish: Object.values(viewData.protocolIndication[0])
                  .slice(7, 10)
                  .concat(
                    Object.values(viewData.protocolIndication[1]).slice(7, 10),
                    Object.values(viewData.protocolIndication[2]).slice(7, 10)
                  ),
              })
        }
        ${B.renderContentTitle('Заключение о пригодности')}
        ${B.renderContentText(
          'В объёме проведенной периодической поверки средство измерений признано соответствующим установленным в описании типа метрологическим требованиям и пригодным к дальнейшему применению.'
        )}  
        ${B.renderContentTitle(`Дата проведения поверки<br/>${viewData.verification_date}`)}
      `
    }
  }, [])

  useEffect(() => {
    false && false && console.log(viewData)
  }, [viewData])

  return <React.Fragment>{ViewProtocol && <ViewProtocol.BoxContainer ref={view} />}</React.Fragment>
}

export default ViewProtocolComponent
