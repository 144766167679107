// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import FilterAltIcon from '@mui/icons-material/FilterAlt'

import Selector from '../../components/LocalElements/Selector/reactSelect/Selector'
import DatePicker from '../../components/LocalElements/DatePicker/DatePicker'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import Table from '../../components/LocalElements/Table/Table'
import Skeleton from '../../components/LocalElements/Skeleton/Skeleton'
import RightSideContainer from '../../components/LocalElements/RightSideContainer/RightSideContainer'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import {
  setOpen,
  setTitle,
  setChildren,
  setBA,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  clearCreateData,
  setSearchQuery as setSearchQueryCompanies,
  setViewVariantMain,
  setLanBilling,
  setStatus,
  setTariff,
  setViewVariant,
  setSelectedCompanies,
} from '../../../store/slices/app/views/companies'
import { setOpen as setOpenRightContainer } from '../../../store/slices/app/comps/rightContainer'
import { setName } from '../../../store/slices/app/controlers/pageName'

import useWindowDimensions from '../../hooks/useWindowDimensions'

const Company = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { width } = useWindowDimensions()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const isLoading = useSelector(state => state.companiesReducer.isLoading)
  const data = useSelector(state => state.companiesReducer.companiesList)
  const selectedCompanies = useSelector(state => state.companiesReducer.selectedCompanies)

  const companiesSearchQuery = useSelector(state => state.companiesReducer.searchQuery)
  const viewVariant = useSelector(state => state.companiesReducer.viewVariantMain)
  const lanBillingSelector = useSelector(state => state.companiesReducer.lanBillingSelector)
  const statusSelector = useSelector(state => state.companiesReducer.statusSelector)

  const changeSearchQuery = param => {
    dispatch(setSearchQueryCompanies(param))
  }

  const handleCompanyClick = () => {
    dispatch(clearCreateData())
    navigate('/metriva/companies/create')
  }

  const handleRowClickCompany = params => {
    navigate(`/metriva/companies/${params.row.id}`)
  }

  const handleNavigateTariff = companyId => {
    false && dispatch(setViewVariant(2))
    false && navigate(`/metriva/companies/${companyId}`)
  }

  const getStatusLabel = tariff => {
    switch (tariff) {
      case 'active':
        return 'Активный'
      case 'trial':
        return 'Пробный'
      case 'frozen':
        return 'Замороженный'
      case 'limited':
        return 'Ограниченный'
      case 'deleted':
        return 'Удаленный'
      default:
        return tariff
    }
  }

  const lanBillingList = ['Не выбрано', 'Не подключен', 'Подключен']

  const statusList = ['Не выбрано', 'Пробный', 'Дейсвует', 'Заморожен', 'Ограничен']

  const tariffList = ['Пробный период', 'Индивидуальный', 'Полный доступ']

  const optionsLanBilling = lanBillingList
    ? lanBillingList.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const optionsStatus = statusList
    ? statusList.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const optionsTariff = tariffList
    ? tariffList.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getLanBilling = selectedOption => {
    dispatch(setLanBilling(selectedOption ? selectedOption.value : null))
  }

  const getStatus = selectedOption => {
    dispatch(setStatus(selectedOption ? selectedOption.value : null))
  }

  const getTariff = selectedOption => {
    dispatch(setTariff(selectedOption ? selectedOption.value : null))
  }

  const changeViewVariant = param => dispatch(setViewVariantMain(param))

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  useEffect(() => {
    dispatch(setName('Компании в системе'))
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <BasicTabs
        viewVariant={viewVariant}
        callback={changeViewVariant}
        tabs={[{ label: 'Действующие клиенты' }, { label: 'Архивные клиенты' }]}
      />

      {viewVariant === 0 && (
        <div style={{ paddingTop: '12px' }}>
          {/* <RightSideContainer blockTitle={"Фильтры"}>
            <div className="right-side-container">
              <div className="right-side-container-block">
                <span
                  className="label-filter"
                >
                  Статус LanBilling
                </span>
                <div style={{ width: "100%" }}>
                  <Selector
                    options={optionsLanBilling}
                    placeholder={"Выберите статус LanBilling"}
                    isSearchable={true}
                    onChange={getLanBilling}
                    value={
                      lanBillingSelector
                        ? optionsLanBilling.find(
                            (option) => option.value === lanBillingSelector
                          )
                        : null
                    }
                  />
                </div>
              </div>
              <div className="right-side-container-block">
                <span
                  className="label-filter"
                >
                  Статус тарифа
                </span>
                <div style={{ width: "100%" }}>
                  <Selector
                    options={optionsStatus}
                    placeholder={"Выберите статус тарифа"}
                    isSearchable={true}
                    onChange={getStatus}
                    value={
                      statusSelector
                        ? optionsStatus.find(
                            (option) => option.value === statusSelector
                          )
                        : null
                    }
                  />
                </div>
              </div>
              <div className="right-side-container-block">
                <span
                  className="label-filter"
                >
                  Выбранный тариф
                </span>
                <div style={{ width: "100%" }}>
                  <Selector
                    options={optionsTariff}
                    isSearchable={true}
                    onChange={getTariff}
                    placeholder={"Выберите тариф"}
                  />
                </div>
              </div>
              <div className="right-side-container-block">
                <span
                  className="label-filter"
                >
                  Регистрация в системе
                </span>
                <DatePicker />
              </div>
              <Button
                variant="outlined"
                onClick={() => {}}
                style={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                }}
              >
                Сбросить все значения
              </Button>
            </div>
          </RightSideContainer> */}
          <div
            style={
              width > 1050
                ? {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '12px',
                  }
                : {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }
            }
          >
            <div style={{ width: '18%' }}>
              <Button
                variant='contained'
                onClick={handleCompanyClick}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '0px 20px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                  height: '54.4px',
                }}
              >
                Добавить компанию
              </Button>
            </div>
            <div style={{ width: width > 1050 ? '82%' : '100%' }}>
              <SearchQuery searchQuery={companiesSearchQuery} setSearchQuery={changeSearchQuery} />
            </div>

            <div style={{ width: width > 1050 ? '16%' : '100%', position: 'relative' }}>
              <Button
                variant='outlined'
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterAltIcon />}
                style={{
                  width: '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '0px 20px',
                  borderRadius: '12px',
                  height: '54.4px',
                }}
              >
                Фильтры
              </Button>
            </div>
          </div>

          {selectedCompanies.length > 0 && (
            <div className='flexContainerWithGap' style={{ marginTop: '11px', gap: '0px' }}>
              <Button
                variant='text'
                startIcon={
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C21.8063 19.2647 21.9744 17.3219 21.9966 13.75H18.8397C17.8659 13.75 17.6113 13.766 17.3975 13.8644C17.1838 13.9627 17.0059 14.1456 16.3722 14.8849L15.6794 15.6933C15.1773 16.2803 14.7796 16.7453 14.2292 16.9984C13.6789 17.2515 13.067 17.2509 12.2945 17.2501H11.7055C10.933 17.2509 10.3211 17.2515 9.77076 16.9984C9.22038 16.7453 8.82271 16.2803 8.32058 15.6933L7.62784 14.8849C6.9941 14.1456 6.81622 13.9627 6.60245 13.8644C6.38869 13.766 6.13407 13.75 5.16026 13.75H2.00339C2.02561 17.3219 2.19367 19.2647 3.46447 20.5355Z'
                      fill='#4B5962'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M7.25 7C7.25 6.58579 7.58579 6.25 8 6.25H16C16.4142 6.25 16.75 6.58579 16.75 7C16.75 7.41421 16.4142 7.75 16 7.75H8C7.58579 7.75 7.25 7.41421 7.25 7Z'
                      fill='#1C274C'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12L22 12.25L18.7055 12.2499C17.933 12.2491 17.3211 12.2485 16.7708 12.5016C16.2204 12.7547 15.8227 13.2197 15.3206 13.8067L14.6278 14.6151C13.9941 15.3544 13.8162 15.5373 13.6025 15.6356C13.3887 15.734 13.1341 15.75 12.1603 15.75H11.8397C10.8659 15.75 10.6113 15.734 10.3975 15.6356C10.1838 15.5373 10.0059 15.3544 9.37216 14.6151L8.67941 13.8067C8.17729 13.2197 7.77961 12.7547 7.22924 12.5016C6.67886 12.2485 6.06705 12.2491 5.29454 12.2499L2.00001 12.25L2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2ZM9.25 10.5C9.25 10.0858 9.58579 9.75 10 9.75H14C14.4142 9.75 14.75 10.0858 14.75 10.5C14.75 10.9142 14.4142 11.25 14 11.25H10C9.58579 11.25 9.25 10.9142 9.25 10.5Z'
                      fill='#4B5962'
                    />
                  </svg>
                }
                onClick={() => {}}
                style={{
                  width: width > 1050 ? '' : '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  borderRadius: '12px',
                  color: '#123532',
                }}
              >
                Архивировать компании
              </Button>
            </div>
          )}

          {isLoading ? (
            <Skeleton />
          ) : (
            <Table
              colData={[
                {
                  field: 'registrationCompany',
                  headerName: 'Регистрация',
                },
                { field: 'name', headerName: 'Наименование' },
                { field: 'inn', headerName: 'ИНН' },
                {
                  field: 'lanBilling',
                  headerName: 'LanBilling',
                  renderCell: params => {
                    const style = {
                      color: params.value === 'Подключен' ? 'green' : 'red',
                    }
                    return <div style={style}>{params.value}</div>
                  },
                },
                { field: 'status', headerName: 'Статус тарифа' },
                { field: 'tariff', headerName: 'Тариф' },
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Действия',
                  getActions: item => {
                    const localItem = item

                    return [
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: '15px',
                        }}
                      >
                        <span
                          onClick={() => handleNavigateTariff(localItem.id)}
                          style={{
                            display: 'flex',
                            flexFlow: 'row',
                            gap: '16px',
                          }}
                        >
                          <p className='icon' style={{ fontSize: '14px', fontWeight: 500 }}>
                            Настроить тариф
                          </p>
                        </span>
                      </div>,
                    ]
                  },
                },
              ]}
              rowData={data
                .filter(item => item?.status !== 'deleted')
                .map(item => ({
                  id: item?.id,
                  registrationCompany: moment(item?.created_at.full).format('DD-MM-YYYY'),
                  name: item?.name,
                  inn: item?.inn,
                  status: getStatusLabel(item?.status),
                  tariff:
                    item?.subscription_id === 2
                      ? 'Полный доступ'
                      : item?.subscription_id === 1
                        ? 'Пробный период'
                        : 'Индивидуальный',
                  lanBilling: item?.billing?.is_synchronized ? 'Подключен' : 'Не подключен',
                }))}
              rowCallback={handleRowClickCompany}
              rowSelectionCallback={array => {
                dispatch(setSelectedCompanies(array))
              }}
              hasCheckboxSelection={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          )}
        </div>
      )}
      {viewVariant === 1 && (
        <div style={{ paddingTop: '12px' }}>
          <RightSideContainer blockTitle={'Фильтры данных'}>
            <React.Fragment>
              <div
                style={{
                  width: '100%',
                }}
              >
                <p
                  style={{
                    display: 'block',
                    width: '100%',
                    marginBottom: '9px',
                    fontSize: '15px',
                  }}
                >
                  Статус LanBilling
                </p>
                <Selector
                  options={optionsLanBilling}
                  isSearchable={true}
                  onChange={getLanBilling}
                  value={
                    lanBillingSelector
                      ? optionsLanBilling.find(option => option.value === lanBillingSelector)
                      : null
                  }
                />
              </div>
            </React.Fragment>
          </RightSideContainer>
          <div className='flexContainerWithGap' style={{ gap: '12px' }}>
            <div style={{ width: '18%' }}>
              <Button
                variant='contained'
                disabled={true}
                onClick={handleCompanyClick}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '0px 20px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                  height: '54.4px',
                }}
              >
                Добавить компанию
              </Button>
            </div>
            <div style={{ width: '82%' }}>
              <SearchQuery searchQuery={companiesSearchQuery} setSearchQuery={changeSearchQuery} />
            </div>

            <div style={{ width: '16%' }}>
              <Button
                variant='outlined'
                disabled={true}
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterAltIcon />}
                style={{
                  width: '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '0px 20px',
                  borderRadius: '12px',
                  height: '54.4px',
                }}
              >
                Фильтры
              </Button>
            </div>
          </div>

          {selectedCompanies.length > 0 && (
            <div className='flexContainerWithGap' style={{ marginTop: '11px', gap: '0px' }}>
              <Button
                variant='text'
                startIcon={
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.7141 22 12C22 7.28598 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM9.25871 7.97395C9.56308 7.693 9.58205 7.21851 9.3011 6.91414C9.02015 6.60978 8.54565 6.5908 8.24129 6.87175L5.99129 8.94867C5.83748 9.09065 5.75 9.29045 5.75 9.49977C5.75 9.7091 5.83748 9.9089 5.99129 10.0509L8.24129 12.1278C8.54565 12.4088 9.02015 12.3898 9.3011 12.0854C9.58205 11.781 9.56308 11.3065 9.25871 11.0256L8.41824 10.2498H14.0385C15.536 10.2498 16.75 11.4638 16.75 12.9613C16.75 14.4589 15.536 15.6729 14.0385 15.6729H9.5C9.08579 15.6729 8.75 16.0086 8.75 16.4229C8.75 16.8371 9.08579 17.1729 9.5 17.1729H14.0385C16.3644 17.1729 18.25 15.2873 18.25 12.9613C18.25 10.6353 16.3644 8.74977 14.0385 8.74977H8.41824L9.25871 7.97395Z'
                      fill='#4B5962'
                    />
                  </svg>
                }
                onClick={() => {}}
                style={{
                  width: width > 1050 ? '' : '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  borderRadius: '12px',
                  color: '#123532',
                }}
              >
                Восстановить
              </Button>
              <Button
                variant='text'
                startIcon={
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M2.75 6.16667C2.75 5.70644 3.09538 5.33335 3.52143 5.33335L6.18567 5.3329C6.71502 5.31841 7.18202 4.95482 7.36214 4.41691C7.36688 4.40277 7.37232 4.38532 7.39185 4.32203L7.50665 3.94993C7.5769 3.72179 7.6381 3.52303 7.72375 3.34536C8.06209 2.64349 8.68808 2.1561 9.41147 2.03132C9.59457 1.99973 9.78848 1.99987 10.0111 2.00002H13.4891C13.7117 1.99987 13.9056 1.99973 14.0887 2.03132C14.8121 2.1561 15.4381 2.64349 15.7764 3.34536C15.8621 3.52303 15.9233 3.72179 15.9935 3.94993L16.1083 4.32203C16.1279 4.38532 16.1333 4.40277 16.138 4.41691C16.3182 4.95482 16.8778 5.31886 17.4071 5.33335H19.9786C20.4046 5.33335 20.75 5.70644 20.75 6.16667C20.75 6.62691 20.4046 7 19.9786 7H3.52143C3.09538 7 2.75 6.62691 2.75 6.16667Z'
                      fill='#4B5962'
                    />
                    <path
                      d='M11.6068 21.9998H12.3937C15.1012 21.9998 16.4549 21.9998 17.3351 21.1366C18.2153 20.2734 18.3054 18.8575 18.4855 16.0256L18.745 11.945C18.8427 10.4085 18.8916 9.6402 18.45 9.15335C18.0084 8.6665 17.2628 8.6665 15.7714 8.6665H8.22905C6.73771 8.6665 5.99204 8.6665 5.55047 9.15335C5.10891 9.6402 5.15777 10.4085 5.25549 11.945L5.515 16.0256C5.6951 18.8575 5.78515 20.2734 6.66534 21.1366C7.54553 21.9998 8.89927 21.9998 11.6068 21.9998Z'
                      fill='#4B5962'
                    />
                  </svg>
                }
                onClick={() => {}}
                style={{
                  width: width > 1050 ? '' : '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  borderRadius: '12px',
                  color: '#123532',
                }}
              >
                Полностью удалить
              </Button>
            </div>
          )}

          {isLoading ? (
            <Skeleton />
          ) : (
            <Table
              colData={[
                {
                  field: 'registrationCompany',
                  headerName: 'Приостановление обслуживания',
                },
                { field: 'name', headerName: 'Наименование' },
                { field: 'inn', headerName: 'ИНН' },
                {
                  field: 'lanBilling',
                  headerName: 'LanBilling',
                  renderCell: params => {
                    const style = {
                      color: params.value === 'Подключен' ? 'green' : 'red',
                    }
                    return <div style={style}>{params.value}</div>
                  },
                },
                { field: 'status', headerName: 'Статус тарифа' },
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Действия',
                  getActions: item => {
                    return [
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: '15px',
                        }}
                      >
                        <Tooltip title='Восстановить компанию' placement='top-end'>
                          <span style={{ width: '30%' }}>
                            <i>
                              <svg
                                width='22'
                                height='22'
                                viewBox='0 0 26 25'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <g clipPath='url(#clip0_553_2371)'>
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M2.96244 2.13822C1.20508 3.89558 1.20508 6.72401 1.20508 12.3809C1.20508 18.0377 1.20508 20.8661 2.96244 22.6235C4.7198 24.3809 7.54822 24.3809 13.2051 24.3809C18.8619 24.3809 21.6904 24.3809 23.4477 22.6235C25.2051 20.8661 25.2051 18.0377 25.2051 12.3809C25.2051 6.72404 25.2051 3.89558 23.4477 2.13822C21.6904 0.380859 18.8619 0.380859 13.2051 0.380859C7.54822 0.380859 4.7198 0.380859 2.96244 2.13822ZM9.91553 7.5496C10.2808 7.21246 10.3035 6.64307 9.9664 6.27783C9.62926 5.91259 9.05986 5.88982 8.69463 6.22696L5.99463 8.71927C5.81005 8.88964 5.70508 9.1294 5.70508 9.38059C5.70508 9.63178 5.81005 9.87154 5.99463 10.0419L8.69462 12.5342C9.05986 12.8714 9.62926 12.8486 9.9664 12.4834C10.3035 12.1181 10.2808 11.5487 9.91553 11.2116L8.90696 10.2806H15.6512C17.4483 10.2806 18.9051 11.7374 18.9051 13.5344C18.9051 15.3315 17.4483 16.7883 15.6512 16.7883H10.2051C9.70803 16.7883 9.30508 17.1912 9.30508 17.6883C9.30508 18.1853 9.70803 18.5883 10.2051 18.5883H15.6512C18.4424 18.5883 20.7051 16.3256 20.7051 13.5344C20.7051 10.7433 18.4424 8.48059 15.6512 8.48059H8.90696L9.91553 7.5496Z'
                                    fill='#7e8299'
                                  />
                                </g>
                                <defs>
                                  <clipPath id='clip0_553_2371'>
                                    <rect
                                      x='0.705078'
                                      y='0.380859'
                                      width='25'
                                      height='24'
                                      rx='5'
                                      fill='white'
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </i>
                          </span>
                        </Tooltip>

                        <Tooltip title='Удалить компанию' placement='top-end'>
                          <span
                            style={{ width: '30%' }}
                            onClick={() => {
                              dispatch(setOpen(true))
                              dispatch(setTitle('Вы действительно хотите удалить компанию?'))
                              dispatch(
                                setChildren('После удаления все данные будут стерты из системы.'),
                              )
                              dispatch(setBA('Удалить'))
                            }}
                          >
                            <i>
                              <svg
                                width='21'
                                height='20'
                                viewBox='0 0 21 23'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M0.683594 5.06126C0.683594 4.56267 1.05776 4.15849 1.51931 4.15849L4.40557 4.15801C4.97904 4.14231 5.48494 3.74841 5.68008 3.16568C5.68521 3.15036 5.69111 3.13146 5.71227 3.06289L5.83663 2.65979C5.91274 2.41263 5.97904 2.19731 6.07182 2.00484C6.43836 1.24448 7.11651 0.716475 7.90018 0.581293C8.09855 0.547075 8.30862 0.54722 8.54974 0.547386H12.3176C12.5588 0.54722 12.7688 0.547075 12.9672 0.581293C13.7509 0.716475 14.429 1.24448 14.7956 2.00484C14.8883 2.19731 14.9546 2.41263 15.0307 2.65979L15.1551 3.06289C15.1763 3.13146 15.1822 3.15036 15.1873 3.16568C15.3824 3.74841 15.9887 4.14279 16.5622 4.15849H19.3479C19.8094 4.15849 20.1836 4.56267 20.1836 5.06126C20.1836 5.55985 19.8094 5.96403 19.3479 5.96403H1.51931C1.05776 5.96403 0.683594 5.55985 0.683594 5.06126Z'
                                  fill='#7e8299'
                                />
                                <path
                                  d='M10.2784 22.2139H11.1309C14.0641 22.2139 15.5306 22.2139 16.4842 21.2787C17.4377 20.3436 17.5352 18.8096 17.7303 15.7417L18.0115 11.3212C18.1173 9.65655 18.1703 8.82425 17.6919 8.29683C17.2136 7.76941 16.4058 7.76941 14.7901 7.76941H6.61923C5.00361 7.76941 4.1958 7.76941 3.71744 8.29683C3.23908 8.82425 3.29202 9.65655 3.39788 11.3212L3.67901 15.7417C3.87412 18.8096 3.97167 20.3436 4.92521 21.2787C5.87875 22.2139 7.34531 22.2139 10.2784 22.2139Z'
                                  fill='#7e8299'
                                />
                              </svg>
                            </i>
                          </span>
                        </Tooltip>
                      </div>,
                    ]
                  },
                },
              ]}
              rowData={data
                .filter(item => item?.status === 'deleted')
                .map(item => ({
                  id: item?.id,
                  registrationCompany: moment(item?.created_at.full).format('DD-MM-YYYY'),
                  name: item?.name,
                  inn: item?.inn,
                  status: getStatusLabel(item?.status),
                  lanBilling: item?.billing?.is_synchronized ? 'Подключен' : 'Не подключен',
                }))}
              rowCallback={handleRowClickCompany}
              rowSelectionCallback={array => {
                dispatch(setSelectedCompanies(array))
              }}
              hasCheckboxSelection={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          )}
        </div>
      )}
    </main>
  )
}

export default Company
