// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenuVertical'
import moment from 'moment'
import cases from '../../../img/menu/cases.svg'
import R from '../../../services/app/request.service'
import Feedback from '../../components/LocalElements/Feedback/Feedback'
import { setShow } from '../../../store/slices/app/comps/feedback'
import { Typography } from '@mui/material'
import PencilIcon from '../../../img/icon/PencilIcon'
import Tooltip from '../../../ui/tooltip/index'
import WarningIcon from '../../../img/icon/Warning'
import theme from '../../../utils/theme.ts'
import PdfIcon from '../../../img/icon/PdfIcon.jsx'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

const ShowCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const { cases_id } = useParams()
  const show = useSelector(state => state.feedbackReducer.show)
  const [data, setData] = useState([])
  const [viewVariant, setViewVariant] = useState(0)

  const statusTranslations = {
    in_storage: 'На хранении',
    in_use: 'В эксплуатации',
    verification: 'Поверка',
    attestation: 'Аттестация',
    calibration: 'Калибровка',
    repair: 'Ремонт',
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: caseData } = await R.getCasesShow(cases_id)
        const data = caseData.data

        setData(data)
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [])

  const navigateToProtocols = () => {
    navigate('/metriva/cases')
  }

  const handlePdf = async deviceId => {
    const kit = data.kits.find(item => item.device_id === deviceId)
    const passportPdfId = kit?.passport_pdf_id

    if (!passportPdfId) {
      dispatch(setMessage('PDF ID не найден.'))
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      return
    }

    const requestData = {
      ids: [passportPdfId],
    }

    try {
      const { status, data: fileData } = await R.KitPassportsPdf(companyId, requestData)

      if (status === 200) {
        const blob = new Blob([fileData], { type: 'application/pdf' })
        const fileName = `Pasport_sp_№${passportPdfId}.pdf`

        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)

        dispatch(setMessage('PDF успешно скачан.'))
        dispatch(setType('success'))
        dispatch(setOpenSnackbar(true))
      } else {
        dispatch(setType('error'))
        dispatch(setOpenSnackbar(true))
        dispatch(setMessage(fileData.message))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div
            className='box'
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              paddingTop: '60px',
              marginTop: '50px',
            }}
          >
            {/* ---------------------------------------------------------------- */}
            {/* в конечном итоге превратить данный блок в полноценные хлебные крошки */}
            {/* ---------------------------------------------------------------- */}

            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                width: 'calc(100% - 21px)',
                position: 'absolute',
                top: 0,
                marginTop: '-40px',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '80px',
                  height: '80px',
                  zIndex: 2,
                  borderRadius: '50%',
                  backgroundColor: '#e5f3fc',
                  boxShadow:
                    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                }}
              >
                <img
                  alt=''
                  src={cases}
                  style={{
                    width: '60%',
                  }}
                />
              </span>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  width: '100%',
                  background: 'linear-gradient(to right, #e5f3fc 40%, white 80% 100%)',
                  height: '50px',
                  alignItems: 'center',
                  paddingLeft: '30px',
                  paddingRight: '20px',
                  marginLeft: '-14px',
                  borderRadius: '12px',
                  borderBottomRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  borderTop: '1px solid #d7ddea',
                  borderRight: '1px solid #d7ddea',
                  gap: '13px',
                }}
              >
                <span className='fas fa-arrow-left icon_back' onClick={navigateToProtocols} />
                <Typography variant='subtitle2'>Метрива</Typography>
                <span
                  style={{
                    display: 'block',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: 'gray',
                  }}
                />
                <Typography variant='subtitle2'>Комплекты СП</Typography>
                <span
                  style={{
                    display: 'block',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    backgroundColor: 'gray',
                  }}
                />
                <Typography variant='subtitle2'>Комплект СП № {data?.inner_name}</Typography>

                <Tooltip title='Редактировать Комплект СП'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/metriva/cases/${cases_id}/edit`)}
                  >
                    <PencilIcon width={16} height={16} />
                  </div>
                </Tooltip>
              </div>
            </div>

            {/* ---------------------------------------------------------------- */}
            {/* ---------------------------------------------------------------- */}

            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  width: '20%',
                  marginRight: '40px',
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Typography variant='h2'>Комплект СП № {data?.inner_name}</Typography>
                <BasicTabs
                  viewVariant={viewVariant}
                  callback={setViewVariant}
                  tabs={
                    data.kits
                      ? [
                          { label: 'Внутренние данные комплекта' },
                          { label: 'Область измерения' },
                          ...data.kits.map(kit => ({
                            label: (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                {kit?.device_name}
                                {!kit.is_sync_available && <WarningIcon />}
                              </div>
                            ),
                          })),
                        ]
                      : [{ label: 'Внутренние данные комплекта' }, { label: 'Область измерения' }]
                  }
                />
              </div>

              {viewVariant === 0 && (
                <div
                  style={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Внутренний номер СП :</Typography>
                    <Typography variant='subtitle1'>
                      {data.inner_name ? data.inner_name : 'Нет информации'}
                    </Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Поверитель :</Typography>
                    <Typography variant='subtitle1'>
                      {data.arshinMaster?.surname
                        ? `${data.arshinMaster?.surname} ${data.arshinMaster?.name} ${data.arshinMaster?.patr}`
                        : 'Нет информации'}
                    </Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Снилс :</Typography>
                    <Typography variant='subtitle1'>
                      {data.arshinMaster?.snils ? data.arshinMaster?.snils : 'Нет информации'}
                    </Typography>
                  </div>
                </div>
              )}
              {viewVariant === 1 && (
                <div
                  style={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Область измерения :</Typography>
                    <Typography variant='subtitle1'>{data?.method?.measurement?.name}</Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Методика поверки :</Typography>
                    <Typography variant='subtitle1'>{data?.method?.name}</Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Способ поверки :</Typography>
                    <Typography variant='subtitle1'>
                      {data?.points
                        ?.map(item =>
                          item.verification_method === 'field' ? 'На выезде' : 'В лаборатории'
                        )
                        .join(', ')}
                    </Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography variant='subtitle2'>Пункты методики :</Typography>
                    <Typography variant='subtitle1'>
                      {data?.points?.map(item => item.name).join(', ')}
                    </Typography>
                  </div>
                </div>
              )}

              {data.kits && viewVariant > 1 && (
                <div style={{ width: '80%' }}>
                  {data.kits.map((item, index) => (
                    <React.Fragment>
                      {viewVariant === index + 2 && (
                        <div
                          key={item.device_id}
                          style={{
                            width: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                          }}
                        >
                          {!item.is_sync_available && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: theme.palette.background.error,
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {!item.is_sync_available
                                  ? 'Средство проверки выведено из эксплуатации'
                                  : ''}
                              </Typography>
                            </div>
                          )}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Статус :</Typography>
                            <Typography variant='subtitle1'>
                              {statusTranslations[item.status]}
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Группа СП :</Typography>
                            <Typography variant='subtitle1'>{item.kit_group_name}</Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Наименование :</Typography>
                            <Typography variant='subtitle1'>{item.name}</Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Тип :</Typography>
                            <Typography variant='subtitle1'>{item.type}</Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Регистрационный № (ФИФ) :</Typography>
                            <Typography variant='subtitle1'>{item.register_number}</Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Заводской № :</Typography>
                            <Typography variant='subtitle1'>
                              {item.manufacturer_number || 'Неизвестно'}
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Инвентарный № :</Typography>
                            <Typography variant='subtitle1'>{item.inventory_number}</Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>
                              Ссылка на результаты последней поверки (Аршин) :
                            </Typography>
                            {item.kit_verification_link ? (
                              <a
                                href={item.kit_verification_link}
                                target='_blank'
                                style={{ textDecoration: 'none' }}
                              >
                                <Typography
                                  variant='subtitle1'
                                  color={theme.palette.text.secondary}
                                  sx={{
                                    cursor: 'pointer',
                                    borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                  }}
                                >
                                  {item.kit_verification_link}
                                </Typography>
                              </a>
                            ) : (
                              <Typography variant='subtitle1'>-</Typography>
                            )}
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>
                              Дата истечения срока последней поверки :
                            </Typography>
                            <Typography variant='subtitle1'>
                              {item.kit_verification_expired_date
                                ? moment(item.kit_verification_expired_date).format('DD-MM-YYYY')
                                : '-'}
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>Дата следующего ТО :</Typography>
                            <Typography variant='subtitle1'>
                              {item.kit_maintenance_expired_date
                                ? moment(item.kit_maintenance_expired_date).format('DD-MM-YYYY')
                                : '-'}
                            </Typography>
                          </div>
                          {(userRole === 'super' || userRole === 'admin') && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <Typography variant='subtitle2'>Ссылка на страницу СП :</Typography>
                              <Typography
                                variant='subtitle1'
                                color={theme.palette.text.secondary}
                                sx={{
                                  cursor: 'pointer',
                                  borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                }}
                                onClick={() => navigate(`/metriva/etalon/${item.id}/edit`)}
                              >
                                Средство поверки
                              </Typography>
                            </div>
                          )}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography variant='subtitle2'>
                              Регитсрационная карточка (паспорт)
                            </Typography>
                          </div>

                          <div
                            onClick={() => handlePdf(item.device_id)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '20px',
                              cursor: 'pointer',
                            }}
                          >
                            <PdfIcon color='#899298' width={40} height={40} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                sx={{ fontSize: '16px', fontWeight: '400', color: '#132532' }}
                              >
                                Регистрационная карточка.pdf
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default ShowCases
