// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SearchQuery from '../../LocalElements/Search/SearchQuery'

import { Typography, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PencilIcon from '../../../../img/icon/PencilIcon'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import RestoreIcon from '../../../../img/icon/RestoreIcon'
import Fab from '@mui/material/Fab'
import Table from '../../LocalElements/Table/Table'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import SelectorJournals from '../Select/SelectJournal'
import Selector from '../../LocalElements/Selector/reactSelect/Selector'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'

import Pdf from '../../../../img/icon/PdfIcon'
import Csv from '../../../../img/icon/CsvIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import Excel from '../../../../img/icon/Excel'
import Tooltip from '../../../../ui/tooltip/index'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import theme from '../../../../utils/theme.ts'

import {
  setSearchQuery,
  setArchiveFromDate,
  setArchiveToDate,
  setComplete,
  setJournalIdFilter,
  setEntryId,
  setJournalId,
  changeArchived,
} from '../../../../store/slices/app/views/journals'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  showBD,
} from '../../../../store/slices/app/comps/modal'
import {
  refreshConditions,
  refreshPersonalMetrolog,
  refreshKitVerifications,
  refreshKitPassport,
  refreshReceiveds,
  refreshTickets,
  refreshIssueCase,
  refreshEntriesArchive,
  refreshArchives,
} from '../../../../store/slices/app/controlers/updater'

import { Container, CountLabel, Filter, IconFrame, Wrapper, FrameFilter } from '../style'

import R from '../../../../services/app/request.service'

const TableArchive = () => {
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [deleteJournalDate, setDeleteJournalDate] = useState('')

  const userRole = useSelector(state => state.headerReducer.role)

  const data = useSelector(state => state.journalReducer.dataArchive)
  const journalIdFilter = useSelector(state => state.journalReducer.journalIdFilter)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const archiveFromDate = useSelector(state => state.journalReducer.archiveFromDate)
  const archiveToDate = useSelector(state => state.journalReducer.archiveToDate)
  const complete = useSelector(state => state.journalReducer.complete)

  const selectedRows = useSelector(state => state.journalReducer.archived.selectedRows)
  const deletedDate = useSelector(state => state.journalReducer.archived.deletedDate)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (journalIdFilter) filtersCount++
    if (archiveFromDate) filtersCount++
    if (archiveToDate) filtersCount++

    if (!complete && !journalIdFilter && !archiveFromDate && !archiveToDate) filtersCount = 0
    return filtersCount
  }, [archiveFromDate, archiveToDate, complete, journalIdFilter])

  const resetActions = [setComplete, setArchiveFromDate, setArchiveToDate, setJournalIdFilter]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const handleShowClick = param => {
    dispatch(setEntryId(+param.id))
    dispatch(setJournalId(+param.row.Title.split(' * ')[1]))
    dispatch(refreshEntriesArchive())

    navigate('/metriva/journals/archive_entries')
  }

  const handleUnarchive = async param => {
    const { status } = await R.unarchiveJournals(companyId, param)

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage('Документ был успешно восстановлен'))

      dispatch(refreshArchives())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(`Ошибка при восстановлении документа - ${status}`))
    }
  }

  const handleDelete = param => async deletedDateParam => {
    const { status } = await R.sheduleDeleteJournals(companyId, param, {
      deletion_scheduled_date: deletedDateParam,
    })

    if (status === 200) {
      dispatch(setOpenModal())

      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage('Документ был поставлен на удаление'))

      dispatch(refreshArchives())
      setDeleteJournalDate('')
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(`Ошибка при планировании удаления документа - ${status}`))
    }
  }

  const handleEditClick = param => {
    navigate(`/metriva/journals/${param}/edit_archives`)
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data } = await R.getArchiveEntriesPdf(
      companyId,
      '?' + selectedRows.map(row => `ids[]=${row}`).join('&')
    )

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        selectedRows.length > 1 ? 'archive-selected.zip' : 'archive-selected.pdf'
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadPdf = async () => {
    const { status, data } = await R.getArchivePdf(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке PDF.'))
    }
  }

  const handleDownloadCsv = async () => {
    const { status, data } = await R.getArchiveCsv(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Csv.'))
    }
  }

  const handleDownloadExcel = async () => {
    const { status, data } = await R.getArchiveExcel(companyId)
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive.xlsx')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке Excel.'))
    }
  }

  let colData = [
    { field: 'Num', headerName: '№ п/п' },
    {
      field: 'IncomingDate',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>Дата переноса в архив</div>
      ),
    },
    {
      field: 'Title',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>Наименование документа</div>
      ),
    },
    { field: 'RegNum', headerName: 'Учетный номер' },
    { field: 'CountList', headerName: 'Количество листов' },
    { field: 'WhoName', headerName: 'ФИО передавшего документ в архив' },
    { field: 'FromArchiveDate', headerName: 'Дата выдачи из архива' },
    { field: 'ToArchiveDate', headerName: 'Дата возврата в архив' },
    { field: 'DestrDate', headerName: 'Дата уничтожения' },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {localItem.row.status ? (
              <Tooltip title='Редактировать запись'>
                <div
                  onClick={() => {
                    handleEditClick(localItem.row.id)
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <PencilIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Восстановить документ'>
                <div
                  onClick={() => {
                    dispatch(setTitle('Восстановление документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(showBA(true))
                    dispatch(showBD(true))
                    dispatch(setBA('Восстановить'))
                    dispatch(setBD('Отменить'))
                    dispatch(
                      setConfirmAction(() => {
                        handleUnarchive(+item.row.id)
                      })
                    )
                    dispatch(setOpenModal(true))
                  }}
                >
                  <RestoreIcon color='#899298' />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Назначить дату удаления'>
              <div
                onClick={() => {
                  dispatch(setTitle('Уничтожение документа'))
                  dispatch(setChildren(modalContentRemove))
                  dispatch(showBA(true))
                  dispatch(showBD(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setBD('Отменить'))
                  dispatch(
                    setConfirmAction(param => {
                      handleDelete(+item.row.id)(param)
                    })
                  )
                  dispatch(setOpenModal(true))
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ]

  let rowData =
    data && data[0] !== null
      ? data.map(item => ({
          id: item.id,
          Num: item.number,
          IncomingDate: item.entry_date,
          Title: item?.journal_type_name + ' * ' + item?.journal_type_id,
          RegNum: item.account_number,
          CountList: item.pages,
          WhoName: item.created_by_name,
          FromArchiveDate: item.issue_date,
          ToArchiveDate: item.return_date,
          DestrDate: item.deletion_scheduled_date,
        }))
      : []

  useEffect(() => {
    return () => {
      dispatch(refreshConditions())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshKitVerifications())
      dispatch(refreshKitPassport())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      dispatch(refreshIssueCase())
    }
  }, [])

  const modalContent = (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <Typography variant='subtitle2'>
        Вы действительно хотите восстановить выбранный документ? После восстановления, все архивные
        записи будут объединены с записями из вкладки "Актуальная версия документа".
      </Typography>
    </div>
  )

  const modalContentRemove = (
    <section
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '620px',
        gap: 22,
      }}
    >
      <Typography variant='subtitle2'>
        Пожалуйста, выберите дату планового уничтожения документа.
      </Typography>
      <Typography variant='subtitle2'>
        Обратите внимание, после уничтожения документ будет удалён из системы окончательно и
        восстановить его будет невозможно.
      </Typography>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          gap: 16,
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexFlow: 'column',
            gap: 9,
          }}
        >
          <Typography variant='subtitle2'>Дата уничтожения документа</Typography>
          <DatePicker
            selectedDate={deletedDate}
            dateChange={date => {
              dispatch(
                changeArchived({
                  value: 'deletedDate',
                  label: date.format('YYYY-MM-DD'),
                })
              )
            }}
          />
        </div>
      </div>
    </section>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <RightSideContainer blockTitle={'Фильтры'}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle2'>Наименование документа</Typography>
            <SelectorJournals isLabel={false} variant='filter' />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle2' sx={{ opacity: 0.6 }}>
              Передавший в архив
            </Typography>
            <Selector
              placeholder={'Выберите нужного человека'}
              options={[]}
              onChange={() => {}}
              isClearable={true}
              isSearchable={true}
              isDisabled={true}
              value={''}
              noOptionsMessage={() => 'Нет доступных вариантов'}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle2'>Дата переноса в архив</Typography>
            <DatePicker
              selectedDate={archiveFromDate}
              dateChange={date => dispatch(setArchiveFromDate(date.format('YYYY-MM-DD')))}
            />

            <DatePicker
              selectedDate={archiveToDate}
              dateChange={date => dispatch(setArchiveToDate(date.format('YYYY-MM-DD')))}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle2'>Заполнение записи</Typography>
            <RadioButton
              value={complete}
              onChange={event => dispatch(setComplete(event.target.value))}
              trueLabel={'Частичное'}
              falseLabel={'Полное'}
              trueValue={'non-complete'}
              falseValue={'complete'}
            />
          </FrameFilter>
          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
        <Container sx={{ paddingRight: count > 0 ? '50px' : '0px' }}>
          <div style={{ width: isMobile ? '80%' : '100%' }}>
            <SearchQuery
              searchQuery={searchQuery}
              setSearchQuery={changeSearchQuery}
              disabled={false}
            />
          </div>

          <IconFrame>
            {count > 0 ? (
              <Tooltip title='Для скачивания PDF отключите фильтры'>
                <div style={{ cursor: 'default', opacity: '40%' }}>
                  <Pdf />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Скачать в формате PDF'>
                <div style={{ cursor: 'pointer' }} onClick={handleDownloadPdf}>
                  <Pdf />
                </div>
              </Tooltip>
            )}
            <Tooltip title='Скачать документ в Csv'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadCsv}>
                <Csv />
              </div>
            </Tooltip>
            <Tooltip title='Скачать документ в Excel'>
              <div style={{ cursor: 'pointer' }} onClick={handleDownloadExcel}>
                <Excel />
              </div>
            </Tooltip>
          </IconFrame>

          <Filter sx={{ width: isMobile ? '220px' : '100%' }}>
            <Button
              onClick={() => {
                dispatch(setOpenRightContainer(true))
              }}
              startIcon={<FilterIcon />}
              label='Фильтры'
              color='secondary'
            />

            <CountLabel sx={{ opacity: count > 0 ? 1 : 0 }}>{count}</CountLabel>
          </Filter>
          {count > 0 && (
            <Fab
              onClick={handleResetFilter}
              size='small'
              style={{
                position: 'absolute',
                boxShadow:
                  '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#E74953',
                left: '100%',
                marginLeft: '-48px',
                transform: 'scale(0.7)',
              }}
            >
              <CloseIcon style={{ color: '#f8f9fc' }} />
            </Fab>
          )}
        </Container>

        {selectedRows && selectedRows.length > 0 && (
          <div>
            <Button
              onClick={handleDownloadSelectionPdf}
              label='Скачать PDF выбранных документов'
              color='text'
              startIcon={<Pdf color={theme.palette.text.dark} />}
            />
          </div>
        )}
      </div>

      <Table
        colData={colData}
        rowData={rowData}
        rowCallback={handleShowClick}
        rowSelectionCallback={array => {
          dispatch(
            changeArchived({
              value: 'selectedRows',
              label: array,
            })
          )
        }}
        hasCheckboxSelection={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </div>
  )
}

export default TableArchive
