// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import Selector from '../../../LocalElements/Selector/reactSelect/Selector'
import { changeCase } from '../../../../../store/slices/app/views/protocols'
import RadioButton from '../../../../../ui/radio-button/index'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'

const TypeMethod = () => {
  const dispatch = useDispatch()

  const verificationMethod = useSelector(state => state.protocolReducer.case.method)
  const verificationType = useSelector(state => state.protocolReducer.case.verificationType)
  const pointMethod = useSelector(state => state.protocolReducer.case.pointMethod)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  const changeType = event => {
    dispatch(changeCase({ value: 'verificationType', label: event.target.value }))
  }
  const changeMethod = event => {
    dispatch(changeCase({ value: 'method', label: event.target.value }))
  }

  const options = pointMethod
    ? pointMethod.map(item => ({
        value: item.id,
        label: `${item.name} ${item.additional}`,
      }))
    : []

  const getValue = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeCase({
          value: 'pointId',
          label: selectedOption ? selectedOption.value : null,
        }),
      )
      const selectedPoint = pointMethod.find(item => item.id === selectedOption.value)

      if (selectedPoint && selectedPoint.isOpticalSensor !== undefined) {
        dispatch(
          changeCase({
            value: 'opticalSensor',
            label: !!selectedPoint.isOpticalSensor,
          }),
        )
      } else {
        dispatch(changeCase({ value: 'opticalSensor', label: null }))
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Typography variant='h2'>Рамки применения методики</Typography>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          gap: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            width: '25%',
          }}
        >
          <Typography
            variant='subtitle2'
            sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            Способ проведения
            {!!verificationMethod ? <CheckCircleIcon /> : <VisibilityToggleIcon />}
          </Typography>

          <RadioButton
            value={verificationMethod}
            onChange={changeMethod}
            trueLabel={'На выезде'}
            falseLabel={'В лаборатории'}
            trueValue={'field'}
            falseValue={'labaratory'}
            trueDisabled={
              verificationMethod &&
              verificationMethod.length === 1 &&
              verificationMethod[0] === 'labaratory'
            }
            falseDisabled={
              verificationMethod &&
              verificationMethod.length === 1 &&
              verificationMethod[0] === 'field'
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            width: '25%',
          }}
        >
          <Typography
            variant='subtitle2'
            sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            Тип поверки
            {!!verificationType ? <CheckCircleIcon /> : <VisibilityToggleIcon />}
          </Typography>

          <RadioButton
            value={verificationType}
            onChange={changeType}
            trueLabel={'Первичная'}
            falseLabel={'Периодическая'}
            trueValue={'initial'}
            falseValue={'periodic'}
            trueDisabled={
              verificationType &&
              verificationType.length === 1 &&
              verificationType[0] === 'periodic'
            }
            falseDisabled={
              verificationType && verificationType.length === 1 && verificationType[0] === 'initial'
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '50%',
            gap: '8px',
          }}
        >
          <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
            Конкретные разделы и пункты методики, применяемые в ходе поверки
          </Typography>
          <div style={{ width: '100%' }}>
            {options.length === 1 ? (
              <React.Fragment>
                {getValue(options[0])}
                <Selector
                  placeholder={'Выберете раздел методики из списка'}
                  onChange={getValue}
                  options={options}
                  isClearable={false}
                  isSearchable={false}
                  value={options[0]}
                  isRequired={true}
                />
              </React.Fragment>
            ) : (
              <Selector
                placeholder={'Выберете раздел методики из списка'}
                onChange={getValue}
                options={options}
                isClearable={false}
                isSearchable={true}
                noOptionsMessage={() => 'Для этой методики нет доступных пунктов поверки'}
                value={pointId ? options.find(option => option.value === pointId) : null}
                isRequired={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeMethod
