import React, { useState } from 'react'
import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  Typography,
  Drawer,
  styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Selector from '../Selector/reactSelect/Selector.jsx'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDrawerOpen } from '../../../../store/slices/app/controlers/drawerSlice.js'

const CustomSliderFromMaterialUI = styled(Slider)(({ theme }) => ({
  color: '#007bff',
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#F8F9FC',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'transparent',
  },
  '& .MuiSlider-markLabel': {
    fontFamily: '"Wix Madefor Display", sans-serif',
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: 'inset 0px 0px 4px -2px #000',
    backgroundColor: '#d0d0d0',
  },
}))

const CustomToolbar = ({ columns = [], filters, setFilters, rowData = [] }) => {
  const dispatch = useDispatch()

  const isDrawerOpen = useSelector(state => state.drawer.isDrawerOpen)

  const handleChange = field => value => {
    setFilters({
      ...filters,
      [field]: value,
    })
  }

  const handleClearFilters = () => {
    const clearedFilters = {}
    columns.forEach(col => {
      if (!col.disableFilter) {
        clearedFilters[col.field] = ''
      }
    })
    setFilters(clearedFilters)
  }

  const renderFilterControl = (col, isLastInRow = false) => {
    if (col.disableFilter) return null

    const { field, type } = col
    const uniqueValues =
      rowData.length > 0 ? [...new Set(rowData.map(row => row[field] ?? ''))] : []

    const commonStyles = {
      padding: 14,
      borderRadius: 12,
      boxShadow: '0 1px 2px rgba(62, 62, 62, 0.28), 0 1px 2px rgba(62, 62, 62, 0.28)',
      transition: 'transform 0.35s, box-shadow 0.35s',
      height: '100%',
    }

    if (type === 'number') {
      const validValues = uniqueValues.filter(value => typeof value === 'number')
      const minValue = validValues.length > 0 ? Math.min(...validValues) : 0
      const maxValue = validValues.length > 0 ? Math.max(...validValues) : 100
      const currentValue = filters[field] || [minValue, maxValue]

      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div style={commonStyles} className='elevation-filter-wrapper'>
            <Box display='flex' flexDirection='column' gap={1}>
              <Typography variant='body2'>
                {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
              </Typography>
              <CustomSliderFromMaterialUI
                sx={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                size='medium'
                value={currentValue}
                onChange={(e, newValue) => handleChange(field)(newValue)}
                valueLabelDisplay='off'
                min={minValue}
                max={maxValue}
                marks={Array.from({ length: maxValue - minValue + 1 }, ($$, i) => ({
                  value: minValue + i,
                  label: `${minValue + i}`,
                }))}
              />
            </Box>
          </div>
        </Grid>
      )
    } else if (type === 'boolean') {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div style={commonStyles} className='elevation-filter-wrapper'>
            <Box display='flex' alignItems='center' flexDirection='column' gap={2}>
              <Typography variant='body2' sx={{ fontSize: '15px' }}>
                {typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}
              </Typography>
              <RadioGroup
                row
                value={filters[field] || ''}
                onChange={e => handleChange(field)(e.target.value)}
              >
                <FormControlLabel value='true' control={<Radio />} label='Да' />
                <FormControlLabel value='false' control={<Radio />} label='Нет' />
                <FormControlLabel value='' control={<Radio />} label='Все' />
              </RadioGroup>
            </Box>
          </div>
        </Grid>
      )
    } else if (uniqueValues.length <= 6 && uniqueValues.length > 0) {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div style={commonStyles} className='elevation-filter-wrapper'>
            <Selector
              placeholder={'Выберите нужный параметр'}
              options={uniqueValues.map(value => ({ value, label: value }))}
              onChange={option => handleChange(field)(option?.value ?? '')}
              value={filters[field] ? { value: filters[field], label: filters[field] } : null}
              isClearable
              isSearchable={false}
              noOptionsMessage={() => 'Нет доступных компаний'}
              label={{
                text: `${typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}`,
                topBg: 'white',
                bottomBg: 'white',
              }}
            />
          </div>
        </Grid>
      )
    } else {
      return (
        <Grid item xs={12} sm={6} md={4} lg={isLastInRow ? true : 4} key={field}>
          <div style={commonStyles}>
            <Input
              label={`${typeof col.headerName === 'string' ? col.headerName : col.headerNameAlt}`}
              placeholder='Введите нужный параметр или его'
              variant='outlined'
              size='small'
              value={filters[field] || ''}
              notRequired
              actions={{ change: handleChange(field) }}
            />
          </div>
        </Grid>
      )
    }
  }

  return (
    <Drawer
      anchor='right'
      open={isDrawerOpen}
      onClose={() => dispatch(setIsDrawerOpen(false))}
      sx={{
        '& .MuiDrawer-paper': {
          width: 400,
          padding: '20px',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box display='flex' flexDirection='column' width='100%' gap={2}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              display: 'block',
              fontSize: '18.8px',
              width: '100%',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Фильтры
          </Typography>
          <span
            onClick={() => dispatch(setIsDrawerOpen(false))}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloseIcon />
          </span>
        </div>
        {columns
          .filter(col => col.field !== 'actions' && !col.disableFilter)
          .map((col, index, array) => (
            <div style={{ width: '100%' }}>
              {renderFilterControl(col, index === array.length - 1)}
            </div>
          ))}
        <Button
          color='secondary'
          onClick={handleClearFilters}
          label={'Сбросить фильтры'}
          fullWidth
        />
      </Box>
    </Drawer>
  )
}

export default CustomToolbar
