// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../../services/app/axios-config.service'

import { setName } from '../../../store/slices/app/controlers/pageName'
import { setEntryId } from '../../../store/slices/app/views/journals'
import { setJournalSettings } from '../../../store/slices/admin/admin'
import {
  refreshConditions,
  refreshReceiveds,
  refreshTickets,
} from '../../../store/slices/app/controlers/updater'

import TableConditions from '../../components/Journals/JournalConditionsCases/Table'
import TableReceived from '../../components/Journals/JournalReceivedForVerification/Table'
import TableTickets from '../../components/Journals/JournalTickets/Table'
import TablePersonalMetrolog from '../../components/Journals/JournalPersonal/Table'
import TableIssueCase from '../../components/Journals/JournalIssueCase/Table'
import TableKitVerifications from '../../components/Journals/JournalKitVerificationsGraph/Table'
import TableKitPassports from '../../components/Journals/JournalsKitPassports/Table'

import R from '../../../services/app/request.service'

const MagazinesIndex = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState([])

  const token = useSelector(state => state.authReducer.authData.token)

  const userRole = useSelector(state => state.headerReducer.role)
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const journalId = useSelector(state => state.journalReducer.journalId)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  useEffect(() => {
    dispatch(setName('История журналов'))
  }, [])
  useEffect(() => {
    !!false && console.log(companyId)

    !!!journalId && navigate('/metriva/journals/archive')
  }, [companyId])

  // ----------------------------------------------------------------
  // техническиий долг - убрать прямое обращение к апи из компонента
  // ----------------------------------------------------------------

  useEffect(() => {
    const fetchMagazine = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/journal-types/list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        const magazines = response.data.data

        false && console.log(magazines)

        setData(magazines)
      } catch (error) {
        console.error(error)
      }
    }

    fetchMagazine()
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setEntryId(''))

      dispatch(refreshConditions())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (companyId && journalId) {
        const { data } = await R.getJournalSettings(companyId)
        if (userRole === 'super' || userRole === 'admin' || true) {
          dispatch(setJournalSettings({ value: 'mainData', label: data.main }))
          dispatch(setJournalSettings({ value: 'personalData', label: data.personal }))
          dispatch(setJournalSettings({ value: 'specificData', label: data.specific }))
        }
      }
    })()
  }, [companyId])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div
        className='path_to_back'
        style={{ marginBottom: 0 }}
        onClick={() => navigate('/metriva/journals/archive')}
      >
        <i
          className='fas fa-arrow-left icon_back'
          style={{ width: '30px', height: '30px', marginRight: '6px' }}
        />
        <p>Все архивные документы</p>
      </div>
      <div className='header_box'>
        <div
          className='selector'
          style={{
            width: '100%',
            height: '48px',
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
          }}
        >
          <h4 style={{ display: 'block', position: 'relative' }}>
            {data.filter(item => item.id === +journalId)[0]
              ? data.filter(item => item.id === +journalId)[0].name + ' - Архив'
              : ''}
          </h4>
        </div>
      </div>

      {(!!!journalId || !!!companyId) && (
        <div
          className='page_null'
          style={{
            borderRadius: '12px',
            background: 'white',
            marginTop: '12px',
            border: '1px solid #d7ddea',
          }}
        >
          <h4
            style={{
              fontSize: '18px',
              letterSpacing: '0.4px',
              color: '#132532',
              fontWeight: 400,
            }}
          >
            Для просмотра записей выберите компанию и документ
          </h4>
        </div>
      )}

      {companyId && (
        <React.Fragment>
          {journalId === 2 && <TableConditions />}
          {journalId === 3 && <TableReceived />}
          {journalId === 4 && <TableTickets />}
          {journalId === 5 && <TableIssueCase />}
          {journalId === 6 && <TablePersonalMetrolog />}
          {journalId === 7 && <TableKitVerifications />}
          {journalId === 8 && <TableKitPassports />}
        </React.Fragment>
      )}
    </main>
  )
}

export default MagazinesIndex
