// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Fab } from '@mui/material'
import FilterIcon from '../../../../../img/icon/FilterIcon'
import PdfIcon from '../../../../../img/icon/PdfIcon'
import { setOpen as setOpenRightContainer } from '../../../../../store/slices/app/comps/rightContainer'
import CloseIcon from '@mui/icons-material/Close'
import SearchQuery from '../../../LocalElements/Search/SearchQuery'
import { Container, CountLabel, Filter } from '../../style'
import theme from '../../../../../utils/theme.ts'
import {
  setSearchQuery,
  resetKitPassportsFilter,
} from '../../../../../store/slices/app/views/journals'
import Button from '../../../../../ui/button/index'
import R from '../../../../../services/app/request.service'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'

const Header = () => {
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const selectedKitPassport = useSelector(state => state.journalReducer.selectedKitPassport)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const systemExistingStatus = useSelector(
    state => state.journalReducer.kitPassportsFilter.systemExistingStatus
  )
  const expirationDateFrom = useSelector(
    state => state.journalReducer.kitPassportsFilter.expirationDateFrom
  )
  const expirationDateTo = useSelector(
    state => state.journalReducer.kitPassportsFilter.expirationDateTo
  )

  const responsiblePassportName = useSelector(
    state => state.journalReducer.kitPassportsFilter.responsiblePassportName
  )

  const count = useMemo(() => {
    let filtersCount = 0

    if (systemExistingStatus) filtersCount++
    if (expirationDateFrom) filtersCount++
    if (expirationDateTo) filtersCount++
    if (responsiblePassportName) filtersCount++

    if (
      !systemExistingStatus &&
      !expirationDateFrom &&
      !expirationDateTo &&
      !responsiblePassportName
    )
      filtersCount = 0
    return filtersCount
  }, [systemExistingStatus, expirationDateFrom, expirationDateTo, responsiblePassportName])

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleResetFilter = () => {
    dispatch(resetKitPassportsFilter())
  }

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  const handlePdf = async () => {
    const data = {
      ids: selectedKitPassport,
    }

    try {
      const { status, data: fileData } = await R.KitPassportsPdf(companyId, data)

      if (status === 200) {
        let blob
        let fileName
        const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\//g, '.')

        if (selectedKitPassport.length === 1) {
          blob = new Blob([fileData], { type: 'application/pdf' })
          fileName = `Pasport_sp_№${selectedKitPassport[0]}.pdf`
        } else {
          blob = new Blob([fileData], { type: 'application/zip' })
          fileName = `pasporta_ps_${currentDate}.zip`
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage('PDF успешно скачан(ы).'))
        dispatch(setType('success'))
        dispatch(setOpenSnackbar(true))
      } else {
        dispatch(setType('error'))
        dispatch(setOpenSnackbar(true))
        dispatch(setMessage(fileData.message))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
      <Container sx={{ paddingRight: count > 0 ? '50px' : '0px' }}>
        <div style={{ width: '100%' }}>
          <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
        </div>

        <Filter>
          <Button
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterIcon />}
            label='Фильтры'
            color='secondary'
          />

          <CountLabel sx={{ opacity: count > 0 ? 1 : 0 }}>{count}</CountLabel>
        </Filter>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </Container>

      {selectedKitPassport.length > 0 && (
        <div>
          <Button
            onClick={handlePdf}
            label='Скачать PDF выбранной карточки'
            color='text'
            startIcon={<PdfIcon color={theme.palette.text.dark} />}
          />
        </div>
      )}
    </div>
  )
}

export default Header
