import React from 'react'
import { StyledButton } from './styles'

const Button = ({
  label,
  onClick,
  startIcon,
  endIcon,
  color = 'primary',
  disabled = false,
  fullWidth = false,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      className={color}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {label}
    </StyledButton>
  )
}

export default Button
