/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Table from '../LocalElements/Table/Table'
import Tooltip from '../../../ui/tooltip'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import PencilIcon from '../../../img/icon/PencilIcon'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { GridActionsCellItem, GridRowId, GridRowModes, GridRowModesModel } from '@mui/x-data-grid'

interface RowDataType {
  id: number
  checkPlace: string
  checkDate: string
  endDate: string
  isTrueMark: boolean
  certNumber: string
  checkData: string
}

interface TableDataType {
  id?: number
  place?: string
  date?: string
  interval?: string
  is_suitable?: boolean
  certificate_number?: string
  link?: string
}

const TabsContainerCheckTable: React.FC = () => {
  const tableData = useSelector(
    (state: any) => state.etalonReducer.createData.kitAttestations,
  ) as TableDataType[]

  const interval: string | number = useSelector(
    (state: any) => state.etalonReducer.createData.interval_attestations,
  )

  const initializeRows = (): RowDataType[] => {
    return tableData && tableData.length > 0
      ? tableData.map((row, index) => ({
          id: row.id ?? index,
          checkPlace: row.place || '',
          checkDate: row.date || '',
          endDate: row.date
            ? dayjs(row.date)
                .add(Number(interval) || 0, 'month')
                .format('YYYY-MM-DD')
            : '',
          isTrueMark: row.is_suitable ?? false,
          certNumber: row.certificate_number || '',
          checkData: row.link || '',
        }))
      : []
  }

  const [rows, setRows] = useState<RowDataType[]>(initializeRows)
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    setRows(initializeRows())
  }, [tableData, interval])

  const handleEditClick = (id: GridRowId) => () => {
    if (id === rows[rows.length - 1].id) {
      setRowModesModel(prevModel => ({
        ...prevModel,
        [id]: { mode: GridRowModes.Edit },
      }))
    }
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }))
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter(row => row.id !== id))
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
  }

  const processRowUpdate = (newRow: RowDataType) => {
    setRows(rows.map(row => (row.id === newRow.id ? newRow : row)))
    return newRow
  }

  const colData = [
    { field: 'checkPlace', headerName: 'Место проведения', editable: true },
    { field: 'checkDate', headerName: 'Дата проведения', editable: true },
    { field: 'endDate', headerName: 'Дата истечения', editable: true },
    { field: 'isTrueMark', headerName: 'Отметка о пригодности', editable: true, type: 'boolean' },
    {
      field: 'certNumber',
      headerName: '№ свидетельства или извещения',
      editable: true,
    },
    {
      field: 'checkData',
      headerName: 'Сведения о результатах поверки',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: ({ id }: { id: GridRowId }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        const isLastRow = id === rows[rows.length - 1].id

        if (isInEditMode && isLastRow) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }
        if (isLastRow) {
          return [
            <Tooltip title={'Редактировать запись целиком'} disableHoverListener={undefined}>
              <GridActionsCellItem
                icon={<PencilIcon onClick={() => {}} />}
                label='Edit'
                onClick={handleEditClick(id)}
                color='inherit'
              />
            </Tooltip>,
            <Tooltip title={'Удалить запись целиком'} disableHoverListener={undefined}>
              <GridActionsCellItem
                icon={<DeleteIcon onClick={() => {}} />}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
              />
            </Tooltip>,
          ]
        }

        return [
          <Tooltip
            title={'Редактирование старых записей недоступно'}
            disableHoverListener={undefined}
          >
            <GridActionsCellItem
              icon={<PencilIcon onClick={() => {}} />}
              label='Edit'
              onClick={() => {}}
              color='inherit'
              sx={{ opacity: 0.6 }}
            />
          </Tooltip>,
          <Tooltip title={'Удалить запись целиком'} disableHoverListener={undefined}>
            <GridActionsCellItem
              icon={<DeleteIcon onClick={() => {}} />}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
            />
          </Tooltip>,
        ]
      },
    },
  ]

  return (
    <Table
      colData={colData}
      rowData={rows}
      rowCallback={() => {}}
      rowSelectionCallback={() => {}}
      hasCheckboxSelection={false}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10 },
        },
      }}
      rowModesModel={rowModesModel}
      onRowModesModelChange={setRowModesModel}
      onProcessRowUpdate={processRowUpdate}
    />
  )
}

export default TabsContainerCheckTable
