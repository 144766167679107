import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import AddBoxIcon from '@mui/icons-material/AddBox'
import Checkbox from '@mui/material/Checkbox'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import Table from '../../LocalElements/Table/Table'
import Input from '../../LocalElements/Input/InputMUI'

import R from '../../../../services/app/request.service'

const Trial = ({ activeTariff }) => {
  const [servicesData, setServicesData] = useState([])

  const statusSelector = useSelector(state => state.companiesReducer.statusSelector)

  const [expandedDescription, setExpandedDescription] = useState(false)
  const [expandedTariff, setExpandedTariff] = useState(false)

  const [selectedServices, setSelectedServices] = useState({})
  const [disabledServices, setDisabledServices] = useState({})
  const [quantityUnits, setQuantityUnits] = useState({})

  const [servicePrise, setServicePrice] = useState('')

  const handleAccordionDescriptionChange = () => {
    setExpandedDescription(prev => !prev)
  }

  const handleAccordionTariffChange = () => {
    setExpandedTariff(prev => !prev)
  }

  const handleCountBillingChange = (id, value) => {
    const numericValue = value.replace(/\D/g, '')
    setQuantityUnits(prev => ({
      ...prev,
      [id]: numericValue,
    }))
  }

  const handleCheckboxChange = id => {
    setSelectedServices(prev => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  let colData = [
    {
      field: 'service',
      headerName: 'Услуга',
      flex: 1.5,
    },
    {
      field: 'description',
      headerName: 'Описание',
      flex: 1.5,
    },
    {
      field: 'billing',
      headerName: 'Биллинг-единица',
      flex: 1,
    },
    {
      field: 'count',
      headerName: 'Количество единиц',
      flex: 1,
      renderCell: params => {
        const { row } = params
        return (
          <Input
            placeholder={'Неизвестно'}
            className={'inputInTable'}
            value={quantityUnits[row.id]}
            actions={{
              change: value => {
                handleCountBillingChange(row.id, value)
              },
            }}
          />
        )
      },
    },
    {
      field: 'prise',
      headerName: 'Стоимость, ₽ (за единицу)',
      flex: 0.6,
    },
    {
      field: 'priseForService',
      headerName: 'Стоимость услуги, ₽(за расчетный период)',
      flex: 0.84,
      renderCell: params => {
        const { row } = params
        const defaultPrice = row.priseForService
        const countBillingValue = quantityUnits[row.id]
        let priceToShow = row.prise

        let servicePrice = defaultPrice

        if (countBillingValue) {
          servicePrice = (countBillingValue * parseFloat(priceToShow)).toFixed(2)
        }

        return servicePrice
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Включить',
      flex: 0.5,
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
            }}
          >
            <Checkbox
              checked={selectedServices[localItem.id]}
              onChange={() => handleCheckboxChange(localItem.id)}
              disabled={disabledServices[localItem.id]}
            />
          </div>,
        ]
      },
    },
  ]

  useEffect(() => {
    if (activeTariff === 'trial') {
      ;(async () => {
        const { data: subscriptionService } = await R.subscriptionService(`/1`)

        const ratesArray = subscriptionService.data.rates

        setServicesData(ratesArray)

        const initialSelectedItems = {}
        ratesArray.forEach(item => {
          initialSelectedItems[item.id] = {
            id: item.id,
            isRequired: item.tariff.is_required,
            price: item.price,
            isHidden: item.is_hidden,
            threshold: item.threshold,
            billing: item.units,
            tariffId: item.tariff_id,
          }
        })

        setSelectedServices(initialSelectedItems)
        setDisabledServices(initialSelectedItems)
      })()
    }
  }, [activeTariff])

  useEffect(() => {
    let totalPrice = 0

    Object.keys(selectedServices).forEach(itemId => {
      const row = selectedServices[itemId]

      if (!row.isHidden) {
        const countBillingValue = quantityUnits[row.id]

        let servicePrice = 0

        if (countBillingValue) {
          servicePrice = (countBillingValue * parseFloat(row.price)).toFixed(2)
        }

        totalPrice += parseFloat(servicePrice)
      }
    })

    setServicePrice(totalPrice.toFixed(2))
  }, [quantityUnits, selectedServices])

  return (
    <React.Fragment>
      <div className='box'>
        <h2 style={{ marginBottom: '26px', fontWeight: 'bold' }}>Тариф «Пробный период»</h2>

        <Accordion
          expanded={expandedDescription}
          onChange={handleAccordionDescriptionChange}
          style={{
            backgroundColor: '#fffff',
            border: '1px solid #d7ddea',
            boxShadow: 'none',
            borderRadius: '12px',
            padding: '14px 0',
            '&::before': {
              display: 'none',
            },
            marginBottom: '20px',
          }}
        >
          <AccordionSummary
            expandIcon={null}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{
              fontSize: '17px',
              fontWeight: 600,
              paddingTop: '0px',
            }}
          >
            {expandedDescription ? (
              <IndeterminateCheckBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            ) : (
              <AddBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            )}
            Краткое описание
          </AccordionSummary>
          <AccordionDetails
            style={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            Тариф «Пробный период» позволяет клиентам ознакомиться с функционалом системы,
            протестировать интерфейс и инструменты без риска внесения изменений в реальные данные,
            так как синхронизация с ФГИСами и системами компании-клиента отключена. Рекомендуем
            начать пробный период для оценки эффективности системы перед принятием решения о
            дальнейшем использовании.
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedTariff}
          onChange={handleAccordionTariffChange}
          style={{
            backgroundColor: '#fffff',
            border: '1px solid #d7ddea',
            boxShadow: 'none',
            borderRadius: '12px',
            padding: '14px 0',
            '&::before': {
              display: 'none',
            },
            marginBottom: '10px',
          }}
        >
          <AccordionSummary
            expandIcon={null}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{
              fontSize: '17px',
              fontWeight: 600,
              paddingTop: '0px',
            }}
          >
            {expandedTariff ? (
              <IndeterminateCheckBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            ) : (
              <AddBoxIcon
                fontSize='medium'
                sx={{
                  color: '#0084e2',
                  marginRight: '10px',
                  marginTop: '-1px',
                }}
              />
            )}
            Управление тарифом
          </AccordionSummary>
          <AccordionDetails
            style={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            <h4 style={{ marginBottom: '15px' }}>Льготная услуга</h4>
            <Table
              css={{
                marginTop: '12px',
                backgroundColor: '#ffffff',
                border: '1px solid #d7ddea',
                '& .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                  wordWrap: 'break-word',
                },
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'default',
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',

                  height: 'auto',
                  lineHeight: '1.5',
                  padding: '8px',
                  boxSizing: 'border-box',
                },
              }}
              colData={colData}
              rowData={servicesData.map(item => ({
                id: item.id,
                service: item?.title,
                description: item?.description,
                billing: item?.units,
                count: '',
                prise: item?.price,
                priseForService: item?.price,
              }))}
              hideFooter={true}
              hasCheckboxSelection={false}
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />

            <div
              className='flexContainerWithGap'
              style={{
                marginBottom: '10px',
                paddingLeft: '10px',
              }}
            >
              <p>Действие тарифа:</p>
              <h4>{statusSelector === 'trial' ? 'Не завершен' : 'Завершен'}</h4>
            </div>
            <div className='flexContainerWithGap' style={{ paddingLeft: '10px' }}>
              <p>Общая стоимость, ₽:</p>
              <h4>{servicePrise}</h4>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  )
}

export default Trial
