// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/request.service'
import { changeReceived, resetReceived } from '../../../../store/slices/app/views/journals'
import { refreshReceiveds } from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

const Edit = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const measurementId = useSelector(state => state.journalReducer.received.measurementId)
  const nameTypeSi = useSelector(state => state.journalReducer.received.nameTypeSi)
  const fifNumber = useSelector(state => state.journalReducer.received.fifNumber)
  const factoryNumber = useSelector(state => state.journalReducer.received.meterFactoryNumber)
  const arshinName = useSelector(state => state.journalReducer.received.customerArshinName)
  const verDate = useSelector(state => state.journalReducer.received.verificationDate)
  const protocolNumber = useSelector(state => state.journalReducer.received.protocolNumber)
  const arshinMasterName = useSelector(state => state.journalReducer.received.arshinMasterName)
  const receivedDate = useSelector(state => state.journalReducer.received.receivedDate)
  const receivedByName = useSelector(state => state.journalReducer.received.receivedByName)
  const issuedName = useSelector(state => state.journalReducer.received.issuedByName)
  const issuedDate = useSelector(state => state.journalReducer.received.issuedDate)
  const totalAccepted = useSelector(state => state.journalReducer.received.totalAccepted)
  const arshinId = useSelector(state => state.journalReducer.received.arshinId)
  const typeMethod = useSelector(state => state.journalReducer.received.typeMethod)
  const [isAutogenerated, setIsAutogenerated] = useState('')

  const changeMeasurement = param => {
    dispatch(changeReceived({ value: 'measurementId', label: param }))
  }
  const changeNameTypeSi = param => {
    dispatch(changeReceived({ value: 'nameTypeSi', label: param }))
  }
  const changeFifNumber = param => {
    dispatch(changeReceived({ value: 'fifNumber', label: param }))
  }
  const changeFactoryNumber = param => {
    dispatch(changeReceived({ value: 'meterFactoryNumber', label: param }))
  }
  const changeArshinName = param => {
    dispatch(changeReceived({ value: 'customerArshinName', label: param }))
  }
  const changeIsArshinName = event => {
    dispatch(changeReceived({ value: 'customerArshinName', label: event.target.value }))
  }
  const changeProtocolNumber = param => {
    dispatch(changeReceived({ value: 'protocolNumber', label: param }))
  }
  const changeMasterName = param => {
    dispatch(changeReceived({ value: 'arshinMasterName', label: param }))
  }
  const changeReceivedByName = param => {
    dispatch(changeReceived({ value: 'receivedByName', label: param }))
  }
  const changeIssuedNamed = param => {
    dispatch(changeReceived({ value: 'issuedByName', label: param }))
  }
  const changeArshinId = param => {
    dispatch(changeReceived({ value: 'arshinId', label: param }))
  }
  const changeTypeMethod = event => {
    dispatch(changeReceived({ value: 'typeMethod', label: event.target.value }))
    dispatch(changeReceived({ value: 'issuedByName', label: '' }))
    dispatch(changeReceived({ value: 'receivedByName', label: '' }))
    dispatch(
      changeReceived({
        value: 'receivedDate',
        label: '',
      })
    )
    dispatch(
      changeReceived({
        value: 'issuedDate',
        label: '',
      })
    )
  }
  const changeTotalAccepted = event => {
    const value = event.target.value === 'true'
    dispatch(changeReceived({ value: 'totalAccepted', label: value }))
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data: receivedData } = await R.getReceivedItem(journal_id, companyId)
        const data = receivedData.data

        setIsAutogenerated(data.is_autogenerated)

        dispatch(
          changeReceived({
            value: 'measurementId',
            label: data?.measurement_id,
          })
        )
        dispatch(
          changeReceived({
            value: 'customerArshinName',
            label: data?.customer_arshin_name,
          })
        )

        dispatch(
          changeReceived({
            value: 'fifNumber',
            label: data?.fif_number,
          })
        )

        dispatch(
          changeReceived({
            value: 'nameTypeSi',
            label: data?.name_type_si,
          })
        )

        dispatch(
          changeReceived({
            value: 'meterFactoryNumber',
            label: data?.meter_factory_number,
          })
        )

        dispatch(
          changeReceived({
            value: 'verificationDate',
            label: data?.verification_date,
          })
        )

        dispatch(
          changeReceived({
            value: 'protocolNumber',
            label: data?.protocol_number,
          })
        )

        dispatch(
          changeReceived({
            value: 'arshinMasterName',
            label: data?.arshin_master_name,
          })
        )

        dispatch(
          changeReceived({
            value: 'totalAccepted',
            label: data?.total_accepted,
          })
        )

        dispatch(
          changeReceived({
            value: 'arshinId',
            label: data?.certificate,
          })
        )

        dispatch(
          changeReceived({
            value: 'typeMethod',
            label: data?.verification_method,
          })
        )

        dispatch(
          changeReceived({
            value: 'issuedByName',
            label: data?.issued_by_name,
          })
        )
        dispatch(
          changeReceived({
            value: 'issuedDate',
            label: data?.issued_date,
          })
        )
        dispatch(
          changeReceived({
            value: 'receivedByName',
            label: data?.received_by_name,
          })
        )
        dispatch(
          changeReceived({
            value: 'receivedDate',
            label: data?.received_date,
          })
        )
      } catch (error) {
        console.error(error)
      }
    })()
  }, [journal_id, companyId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetReceived())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurementId,
      customer_arshin_name: arshinName,
      work_content: 'no-content',
      fif_number: fifNumber,
      name_type_si: nameTypeSi,
      meter_factory_number: factoryNumber,
      verification_date: verDate,
      protocol_number: protocolNumber,
      arshin_master_name: arshinMasterName,
      total_accepted: totalAccepted,
      certificate: arshinId,
      verification_method: typeMethod,
    }

    if (typeMethod === 'laboratory') {
      data.issued_by_name = issuedName
      data.issued_date = issuedDate
      data.received_by_name = receivedByName
      data.received_date = receivedDate
    }

    const { status } = await R.editReceived(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshReceiveds())
      dispatch(resetReceived())
      navigate('/metriva/journals')
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  const measurementLabelIcon = measurementId ? (
    <CheckCircleIcon
      sx={{
        color: '#63E6BE',
        marginLeft: '8px',
        marginTop: '0',
      }}
    />
  ) : (
    <VisibilityToggleIcon
      sx={{
        color: '#FFD43B',
        marginLeft: '8px',
        marginTop: '0',
      }}
    />
  )

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>
            Журнал учета поступивших на поверку СИ и проведения поверочных работ
          </Typography>

          <Content>
            <Typography variant='h2'>Информация о журнале</Typography>
            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Measurement
                    measurementId={measurementId}
                    setMeasurementId={changeMeasurement}
                    label={{
                      text: 'Область измерения',
                      topBg: 'white',
                      bottomBg: 'white',
                    }}
                  />
                </LabelFrame>

                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    label={'Наименование и тип СИ'}
                    placeholder={'Введите наименование и тип СИ'}
                    type={'text'}
                    value={nameTypeSi}
                    actions={{
                      change: changeNameTypeSi,
                    }}
                  />
                </LabelFrame>

                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    label={'Номер СИ в госреестре'}
                    placeholder={'Пример ввода - 349392-93'}
                    type={'text'}
                    value={fifNumber}
                    actions={{
                      change: changeFifNumber,
                    }}
                  />
                </LabelFrame>
              </ContentFrame>
            )}

            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    label={'Заводской номер СИ'}
                    placeholder={'Пример ввода - 3829JH39'}
                    type={'text'}
                    value={factoryNumber}
                    actions={{
                      change: changeFactoryNumber,
                    }}
                  />
                </LabelFrame>

                <div style={{ display: 'flex', flexFlow: 'column', width: 'calc(65.6% + 16px)' }}>
                  <Input
                    label={'Владелец СИ в системе Аршин'}
                    placeholder={'Выберите тип лица или введите наименование владельца СИ'}
                    type={'text'}
                    value={arshinName}
                    actions={{
                      change: changeArshinName,
                    }}
                  />
                  <RadioButton
                    value={arshinName}
                    onChange={changeIsArshinName}
                    trueLabel={'Физическое лицо'}
                    falseLabel={'Юридическое лицо'}
                    trueValue={'Физическое лицо'}
                    falseValue={'Юридическое лицо'}
                  />
                </div>
              </ContentFrame>
            )}

            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <DatePicker
                    selectedDate={verDate}
                    label='Дата поверки'
                    dateChange={date =>
                      date
                        ? dispatch(
                            changeReceived({
                              value: 'verificationDate',
                              label: date.format('YYYY-MM-DD'),
                            })
                          )
                        : dispatch(
                            changeReceived({
                              value: 'verificationDate',
                              label: null,
                            })
                          )
                    }
                  />
                </LabelFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    label={'Номер протокола поверки'}
                    type={'text'}
                    placeholder={'Введите номер протокола'}
                    value={protocolNumber}
                    actions={{
                      change: changeProtocolNumber,
                    }}
                  />
                </LabelFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Input
                    label={'ФИО проводившего поверку'}
                    type={'text'}
                    placeholder={'Введите ФИО проводившего поверку'}
                    value={arshinMasterName}
                    actions={{
                      change: changeMasterName,
                    }}
                  />
                </LabelFrame>
              </ContentFrame>
            )}

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Typography variant='subtitle2'>Способ проведения поверки</Typography>
                <RadioButton
                  value={typeMethod}
                  onChange={changeTypeMethod}
                  trueLabel={'На выезде'}
                  falseLabel={'В лаборатории'}
                  trueValue={'field'}
                  falseValue={'laboratory'}
                  trueDisabled={isAutogenerated}
                  falseDisabled={isAutogenerated}
                />
              </LabelFrame>

              {typeMethod === 'laboratory' && (
                <LabelFrame sx={{ width: 'calc(65.6% + 16px)' }}>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Дата поступления СИ{' '}
                        {!!receivedDate ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <DatePicker
                        selectedDate={receivedDate}
                        dateChange={date =>
                          dispatch(
                            changeReceived({
                              value: 'receivedDate',
                              label: date.format('YYYY-MM-DD'),
                            })
                          )
                        }
                        maxDate={verDate ? dayjs(verDate) : null}
                      />
                    </div>

                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        ФИО принявшего СИ{' '}
                        {!!receivedByName ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <Input
                        placeholder={'Введите ФИО принявшего СИ'}
                        notRequired={true}
                        type={'text'}
                        value={receivedByName}
                        actions={{
                          change: changeReceivedByName,
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Дата выдачи СИ{' '}
                        {!!issuedDate ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <DatePicker
                        selectedDate={issuedDate}
                        dateChange={date =>
                          dispatch(
                            changeReceived({
                              value: 'issuedDate',
                              label: date.format('YYYY-MM-DD'),
                            })
                          )
                        }
                        minDate={verDate ? dayjs(verDate) : null}
                      />
                    </div>

                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        ФИО выдавшего СИ{' '}
                        {!!issuedName ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <Input
                        type={'text'}
                        placeholder={'Введите ФИО выдавшего СИ'}
                        notRequired={true}
                        value={issuedName}
                        actions={{
                          change: changeIssuedNamed,
                        }}
                      />
                    </div>
                  </div>
                </LabelFrame>
              )}
            </ContentFrame>

            {!isAutogenerated && (
              <ContentFrame>
                <LabelFrame sx={{ width: '32.8%' }}>
                  <Typography variant='subtitle2'>Заключение поверки</Typography>
                  <RadioButton
                    value={totalAccepted}
                    onChange={changeTotalAccepted}
                    trueLabel={'Пригоден'}
                    falseLabel={'Непригоден'}
                    trueValue={true}
                    falseValue={false}
                  />
                </LabelFrame>

                <LabelFrame sx={{ width: '32.8%' }}>
                  <Typography variant='subtitle2'>Номер свидетельства / извещения</Typography>
                  <Input
                    placeholder={'Пример ввода - АИК/24-08-2024/382808433'}
                    type={'text'}
                    value={arshinId}
                    actions={{
                      change: changeArshinId,
                    }}
                  />
                </LabelFrame>
              </ContentFrame>
            )}
          </Content>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
