// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import Table from '../../components/LocalElements/Table/Table'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import UserBanIcon from '../../../img/icon/BanUserIcon'
import UserBan from '../../../img/icon/UserBan'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import R from '../../../services/app/request.service'
import { setSearchQuery as setSearchQueryUsers } from '../../../store/slices/app/views/users'
import { refreshUsers } from '../../../store/slices/app/controlers/updater'
import { setName } from '../../../store/slices/app/controlers/pageName'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

const Users = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const userRole = useSelector(state => state.headerReducer.role)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const usersList = useSelector(state => state.usersReducer.usersList)
  const usersSearchQuery = useSelector(state => state.usersReducer.searchQuery)

  const changeSearchQuery = param => {
    dispatch(setSearchQueryUsers(param))
  }
  const handleIsTrustedChange = async (userId, isChecked) => {
    const { status } = await R.changeUserTrust(userId, {
      isTrusted: isChecked,
    })
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус пользователя изменён.'))
      dispatch(setOpen(false))
      dispatch(refreshUsers())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }
  const handleCheckboxBan = async userId => {
    const { status } = await R.changeUserBan(userId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус пользователя изменён.'))
      dispatch(setOpen(false))
      dispatch(refreshUsers())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }
  const handleUsersClick = () => {
    navigate('/metriva/users/create')
  }
  const handleEditClick = userId => {
    navigate(`/metriva/users/${userId}/edit`)
  }
  const handleRowShowClick = params => {
    navigate(`/metriva/users/${params.row.id}`)
  }

  const handleDelete = async userId => {
    const { status } = await R.deleteUser(userId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Пользователь удален'))
      dispatch(setOpen(false))
      dispatch(refreshUsers())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  let colData = [
    { field: 'name', headerName: 'ФИО пользователя' },
    { field: 'job', headerName: 'Роль в системе' },
    {
      field: 'isTrusted',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Опытный
          <Tooltip
            title='Наличие возможности отправлять протоколы во ФГИС без дополнительной проверки'
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i
              className='fas fa-question-circle question'
              style={{ marginLeft: '7px', cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      ),
      renderCell: params => {
        const handleIsTrustedClick = async event => {
          event.stopPropagation()
          const userId = params.row.id
          const isChecked = event.target.checked
          await handleIsTrustedChange(userId, isChecked)
        }

        if (params.row.job !== 'Поверитель') {
          return <Checkbox checked={params.value} disabled />
        } else {
          return (
            <Checkbox
              checked={params.value}
              onClick={handleIsTrustedClick}
              userId={params.row.id}
              isChecked={params.value}
            />
          )
        }
      },
    },
    {
      field: 'lastActive',
      headerName: 'Последняя активность',
    },
    { field: 'login', headerName: 'Логин' },
  ]

  if (userRole === 'super' || userRole === 'admin') {
    colData.splice(8, 0, {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '18px',
            }}
          >
            <Tooltip title='Редактировать пользователя' placement='top-end'>
              <div
                onClick={() => handleEditClick(localItem.id)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <PencilIcon />
              </div>
            </Tooltip>
            {localItem.row.status ? (
              <Tooltip title='Разбанить пользователя' placement='top-end'>
                <div
                  onClick={() => handleCheckboxBan(localItem.id)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '3px',
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Забанить пользователя' placement='top-end'>
                <div
                  onClick={() => handleCheckboxBan(localItem.id)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '3px',
                  }}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Удалить пользователя' placement='top-end'>
              <div
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(setTitle('Вы действительно хотите удалить пользователя?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => {
    dispatch(setName('Пользователи в системе'))
  }, [])

  const noRowsText = 'Данные о пользователях отсутствуют'

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '100%' : '100%',
          }}
        >
          <FilterCompany />
        </div>
      )}
      {!companyId ? (
        <div
          className='page_null'
          style={{
            borderRadius: '12px',
            background: 'white',
            marginTop: '14px',
          }}
        >
          <h4
            style={
              width > 1050
                ? {
                    fontSize: '18px',
                    letterSpacing: '0.4px',
                    color: '#132532',
                    fontWeight: '400',
                  }
                : {
                    fontSize: '17px',
                    letterSpacing: '0.4px',
                    color: '#132532',
                    fontWeight: '400',
                    textAlign: 'center',
                  }
            }
          >
            Для просмотра данных о пользователях выберите компанию
          </h4>
        </div>
      ) : (
        <React.Fragment>
          <div
            className='header_box'
            style={{ margin: userRole === 'super' ? '12px 0' : '0px 0 12px' }}
          >
            {(userRole === 'admin' || userRole === 'super') && (
              <div
                style={{
                  width: width > 1050 ? '18%' : '100%',
                }}
              >
                <Button
                  variant='contained'
                  onClick={handleUsersClick}
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '14px 20px',
                    margin: 0,
                    borderRadius: '12px',
                    width: '100%',
                    height: '54.4px',
                  }}
                >
                  Добавить нового
                </Button>
              </div>
            )}

            <div style={{ width: '82%' }}>
              <SearchQuery searchQuery={usersSearchQuery} setSearchQuery={changeSearchQuery} />
            </div>
            <div style={{ width: width > 1050 ? '16%' : '100%', position: 'relative' }}>
              <Button
                variant='outlined'
                disabled={true}
                onClick={() => {}}
                startIcon={<FilterAltIcon />}
                style={{
                  width: '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '0px 20px',
                  borderRadius: '12px',
                  height: '54.4px',
                }}
              >
                Фильтры
              </Button>
            </div>
          </div>
          <Table
            colData={colData}
            rowData={usersList.map(user => ({
              id: user.id,
              name: user?.fullname,
              job: user?.role,
              login: user.login,
              lastActive: user.last_event
                ? moment(user.last_event).format('DD-MM-YYYY, HH:mm')
                : 'Нет информации',
              isTrusted: user.is_trusted,
              status: user.is_banned,
            }))}
            rowCallback={handleRowShowClick}
            hasCheckboxSelection={false}
            noRowsText={noRowsText}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </main>
  )
}

export default Users
