// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ChangeEvent, ReactElement, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../LocalElements/Input/InputMUI'
import DatePicker from '../LocalElements/DatePicker/DatePicker'
import { Button, Radio, RadioGroup, FormControlLabel } from '@mui/material'

import {
  changeEtalonVerificationsForm,
  resetEtalonVerificationsForm,
  changeEtalonVerifications,
} from '../../../store/slices/app/views/etalon'
import { setOpen } from '../../../store/slices/app/comps/bottomContainer'
import { IModelEtalon__KitVerificationsItem, IModelEtalonCreate } from '../../types'

const CreateCheckItem: React.FC = (): ReactElement => {
  const dispatch = useDispatch()

  const formData: IModelEtalon__KitVerificationsItem = useSelector(
    (state: any) => state.etalonReducer.createData.kitVerificationsForm,
  )
  const etalonCreateData: IModelEtalonCreate = useSelector(
    (state: any) => state.etalonReducer.createData,
  )

  const addRow = (): void => {
    dispatch(
      changeEtalonVerifications({
        place: formData.place,
        date: formData.date,
        interval: etalonCreateData.interval_verifications,
        is_suitable: formData.is_suitable,
        certificate_number: formData.certificate_number,
        link: formData.link,
      }),
    )

    dispatch(resetEtalonVerificationsForm())
    dispatch(setOpen(false))
  }

  const validate = useMemo(() => {
    return (
      formData.place &&
      etalonCreateData.interval_verifications &&
      formData.is_suitable &&
      formData.certificate_number &&
      formData.link
    )
  }, [
    formData.place,
    etalonCreateData.interval_verifications,
    formData.is_suitable,
    formData.certificate_number,
    formData.link,
  ])

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginTop: '10px', marginBottom: '22px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите место проведения'}
            label={'Место проведения (наименование организации)'}
            value={formData.place}
            actions={{
              change: (param: string) =>
                dispatch(changeEtalonVerificationsForm({ value: 'place', label: param })),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
          />
        </div>
        <div className='width50Percent' style={{ display: 'flex', gap: '10px' }}>
          <div className='width50Percent'>
            <DatePicker
              selectedDate={formData.date ? formData.date : null}
              dateChange={(date: { format: (arg0: string) => any }) =>
                dispatch(
                  changeEtalonVerificationsForm({
                    value: 'date',
                    label: date.format('YYYY-MM-DD'),
                  }),
                )
              }
              label='Дата проведения'
            />
          </div>
          <div className='width50Percent' style={{ paddingLeft: '10px' }}>
            <span>Заключение поверки</span>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={formData.is_suitable}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  changeEtalonVerificationsForm({
                    value: 'is_suitable',
                    label: event.target.value,
                  }),
                )
              }}
              style={{
                flexDirection: 'row',
                fontFamily: "'Wix Madefor Display', sans-serif",
                color: '#123532',
                fontSize: '16px',
                fontWeight: '400',
                marginTop: '0px',
                paddingLeft: '5px',
              }}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='Пригоден'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='Непригоден'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className='flexContainerWithGap' style={{ marginBottom: '14px' }}>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Введите № свидетельства или извещения'}
            label={'№ свидетельства или извещения'}
            value={formData.certificate_number}
            actions={{
              change: (param: string) =>
                dispatch(
                  changeEtalonVerificationsForm({ value: 'certificate_number', label: param }),
                ),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
          />
        </div>
        <div className='width50Percent'>
          <Input
            className={'input'}
            type={'text'}
            placeholder={'Вставьте необходимую ссылку'}
            label={'Ссылка на сведения о результатах поверки СИ'}
            value={formData.link}
            actions={{
              change: (param: string) =>
                dispatch(changeEtalonVerificationsForm({ value: 'link', label: param })),
            }}
            pattern={undefined}
            length={undefined}
            style={undefined}
            multiline={undefined}
            minRows={undefined}
            maxRows={undefined}
            sx={undefined}
          />
        </div>
      </div>
      <div
        className='flexContainerWithGap'
        style={{ justifyContent: 'flex-end', alignItems: 'center' }}
      >
        <div style={{ width: '240px' }}>
          <Button
            variant='contained'
            onClick={addRow}
            disabled={validate ? false : true}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              marginTop: '14px',
              borderRadius: '12px',
              width: '100%',
            }}
          >
            {'Добавить поверку'}
          </Button>
        </div>
        <div style={{ width: '240px' }}>
          <Button
            variant='outlined'
            onClick={() => dispatch(resetEtalonVerificationsForm())}
            disabled={false}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              marginTop: '14px',
              borderRadius: '12px',
              width: '100%',
            }}
          >
            Сбросить все значения
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateCheckItem
