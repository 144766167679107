// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Fab, useMediaQuery } from '@mui/material'
import moment from 'moment'
import Table from '../../LocalElements/Table/Table'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import R from '../../../../services/app/request.service'
import { refreshConditions, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import PencilIcon from '../../../../img/icon/PencilIcon'
import {
  setSearchQuery,
  setComplete,
  setDateFrom,
  setDateUntil,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'

import {
  setOpen as setOpenModal,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import Tooltip from '../../../../ui/tooltip/index'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import theme from '../../../../utils/theme.ts'
import { Container, CountLabel, Filter, IconFrame, Wrapper, FrameFilter } from '../style'

import { archiveTitle, archiveMessage, ModalContent } from '../archiveModal/archiveModal'

const TableConditions = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const { handleDownload } = useExcelCsvPdf()

  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const data = useSelector(state => state.journalReducer.dataConditions)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)
  const complete = useSelector(state => state.journalReducer.complete)
  const dateFrom = useSelector(state => state.journalReducer.dateFrom)
  const dateUntil = useSelector(state => state.journalReducer.dateUntil)

  const entryId = useSelector(state => state.journalReducer.entryId)
  const archiveDateFrom = useSelector(state => state.journalReducer.archiveFrom)
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const journalId = useSelector(state => state.journalReducer.journalId)
  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date

  const count = useMemo(() => {
    let filtersCount = 0

    if (complete) filtersCount++
    if (dateFrom) filtersCount++
    if (dateUntil) filtersCount++

    if (!complete && !dateFrom && !dateUntil) filtersCount = 0
    return filtersCount
  }, [complete, dateFrom, dateUntil])

  const resetActions = [setComplete, setDateFrom, setDateUntil]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const changeComplete = event => {
    dispatch(setComplete(event.target.value))
  }

  const handleDateFromChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(''))
    }
  }

  const handleDateUntilChange = date => {
    if (date) {
      dispatch(setDateUntil(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateUntil(''))
    }
  }

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_conditions-of-cases`)
  }

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={archiveDateFromBySettings}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveConditionsJournal(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(false))
      dispatch(refreshConditions())
      dispatch(refreshArchives())
      dispatch(resetState())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
    } else if (status === 409) {
      dispatch(resetState())
      dispatch(setTitle(archiveTitle))
      dispatch(setChildren(archiveMessage))
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpenModal(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpenModal(true))
      dispatch(resetState())
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`
    )

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'archive-selected.pdf')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (archiveDateFromBySettings && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFrom, archiveDateTo])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  let colData = [
    { field: 'conditionDate', headerName: 'Дата' },
    { field: 'temp', headerName: 'Температура, ˚С' },
    { field: 'humidity', headerName: 'Влажность, %' },
    { field: 'pressure', headerName: 'Давление, кПа' },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              gap: '30px',
            }}
          >
            <Tooltip title='Редактировать запись' placement='top-end'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                <PencilIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  return (
    <main>
      <RightSideContainer blockTitle={'Фильтры'}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата регистрации</Typography>
            <DatePicker selectedDate={dateFrom} dateChange={handleDateFromChange} />

            <DatePicker selectedDate={dateUntil} dateChange={handleDateUntilChange} />
          </FrameFilter>

          {!entryId && (
            <FrameFilter>
              <Typography variant='subtitle1'>Заполнение записи</Typography>
              <RadioButton
                value={complete}
                onChange={changeComplete}
                trueLabel={'Частичное'}
                falseLabel={'Полное'}
                trueValue={'non-complete'}
                falseValue={'complete'}
              />
            </FrameFilter>
          )}

          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>

      <Container sx={{ paddingRight: count > 0 ? '50px' : '0px' }}>
        <div style={{ width: '100%' }}>
          <SearchQuery searchQuery={searchQuery} setSearchQuery={changeSearchQuery} />
        </div>

        <IconFrame>
          {count > 0 ? (
            <Tooltip title='Для скачивания PDF отключите фильтры'>
              <div style={{ cursor: 'default', opacity: '40%' }}>
                <Pdf />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Скачать в формате PDF'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  !entryId && handleDownload('pdf')
                  !!entryId && handleDownloadSelectionPdf()
                }}
              >
                <Pdf />
              </div>
            </Tooltip>
          )}
          {!entryId && (
            <Tooltip title='Скачать документ в Csv'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('csv')}>
                <Csv />
              </div>
            </Tooltip>
          )}
          {!entryId && (
            <Tooltip title='Скачать документ в Excel'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleDownload('excel')}>
                <Excel />
              </div>
            </Tooltip>
          )}
          {!entryId && (
            <React.Fragment>
              {count > 0 ? (
                <Tooltip title='Для архивации документа отключите фильтры'>
                  <div style={{ cursor: 'default', opacity: '40%' }}>
                    <ArchiveIcon />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title='Архивировать документ'>
                  <div style={{ cursor: 'pointer' }}>
                    <ArchiveIcon
                      onClick={() => {
                        dispatch(setTitle('Архивация документа'))
                        dispatch(setChildren(modalContent))
                        dispatch(setBA('Архивировать'))
                        dispatch(setBD('Отменить'))
                        dispatch(showBD(true))
                        dispatch(setConfirmAction(handleArchiveClick))
                        dispatch(setOpenModal(true))
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </React.Fragment>
          )}
        </IconFrame>

        <Filter sx={{ width: isMobile ? '220px' : '100%' }}>
          <Button
            onClick={() => {
              dispatch(setOpenRightContainer(true))
            }}
            startIcon={<FilterIcon />}
            label='Фильтры'
            color='secondary'
          />

          <CountLabel sx={{ opacity: count > 0 ? 1 : 0 }}>{count}</CountLabel>
        </Filter>
        {count > 0 && (
          <Fab
            onClick={handleResetFilter}
            size='small'
            style={{
              position: 'absolute',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
              backgroundColor: '#E74953',
              left: '100%',
              marginLeft: '-48px',
              transform: 'scale(0.7)',
            }}
          >
            <CloseIcon style={{ color: '#f8f9fc' }} />
          </Fab>
        )}
      </Container>

      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          conditionDate: false
            ? moment(item.condition_date).format('DD-MM-YYYY')
            : item.condition_date,
          temp: item.temperature_environment,
          humidity: item.relative_humidity,
          pressure: item.atmospheric_pressure,
        }))}
        hasCheckboxSelection={false}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </main>
  )
}

export default TableConditions
