import React from 'react'
import { Typography } from '@mui/material'
import theme from '../../../../utils/theme.ts'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import { ModalFrame, ModalBox, LabelFrame } from '../style'
import dayjs from 'dayjs'

export const archiveTitle = (
  <Typography variant='subtitle1' sx={{ color: theme.palette.secondary.dark }}>
    Архивация приостановлена
  </Typography>
)

export const archiveMessage = (
  <ModalFrame>
    <Typography variant='subtitle1'>
      Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в некоторых
      записях документа
    </Typography>
    <Typography variant='subtitle1'>
      Пожалуйста, найдите такие записи с помощью фильтра "Заполнение записи", дождитесь появления /
      внесите недостающие данные и повторите процесс архивации.
    </Typography>
  </ModalFrame>
)

export const ModalContent = ({
  archiveDateFromBySettings,
  archiveDateTo,
  setArchiveTo,
  dispatch,
}) => (
  <ModalFrame>
    <Typography variant='subtitle2'>
      Вы действительно хотите перенести выбранный документ в архив? Архивная версия документа будет
      доступна только в режиме просмотра.
    </Typography>
    <Typography variant='subtitle2'>
      Если вы уверены, укажите дату окончания действия документа и нажмите "Архивировать".
    </Typography>
    <ModalBox>
      <LabelFrame sx={{ width: '50%', gap: '9px' }}>
        <DatePicker
          selectedDate={archiveDateFromBySettings}
          label={'С какого - значение из настроек'}
          dateChange={() => {}}
          isDisabled={true}
          maxDate={dayjs().subtract(1, 'day')}
          disableFuture={true}
        />
      </LabelFrame>
      <LabelFrame sx={{ width: '50%', gap: '9px' }}>
        <DatePicker
          selectedDate={archiveDateTo}
          label={'По какое (включительно)'}
          dateChange={date => {
            date ? dispatch(setArchiveTo(date.format('YYYY-MM-DD'))) : dispatch(setArchiveTo(null))
          }}
          maxDate={dayjs().subtract(1, 'day')}
          disableFuture={true}
        />
      </LabelFrame>
    </ModalBox>
  </ModalFrame>
)
