// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Collapse, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'

import InfoCircle from '../../../img/icon/InfoCircle'
import FilterIcon from '../../../img/icon/FilterIcon'
import PdfIcon from '../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../img/icon/ArchiveIcon'
import RestoreIcon from '../../../img/icon/RestoreIcon'
import ExportIcon from '../../../img/icon/Export.jsx'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import CopyIcon from '../../../img/icon/Copy'
import CheckCircleIcon from '../../../img/icon/CheckCircleIcon'
import ExitIcon from '../../../img/icon/ExitIcon'

import Csv from '../../../services/app/protocol-csv-excel-download.service'
import R from '../../../services/app/request.service'

import RightSideContainer from '../../components/LocalElements/RightSideContainer/RightSideContainer'
import FilterMeasurement from '../../components/LocalElements/Filter/FilterMeasurement'
import FilterUsers from '../../components/LocalElements/Filter/FilterUsers'
import DatePicker from '../../components/LocalElements/DatePicker/DatePicker'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import Table from '../../components/LocalElements/Table/Table'
import { setState } from '../../components/Methodologies/UniversalShow/state'

import Tooltip from '../../../ui/tooltip/index'
import Button from '../../../ui/button/index'
import RadioButton from '../../../ui/radio-button/index'
import theme from '../../../utils/theme.ts'

import { setName } from '../../../store/slices/app/controlers/pageName'
import { refreshProtocols } from '../../../store/slices/app/controlers/updater'
import { setOpen as setOpenRightContainer } from '../../../store/slices/app/comps/rightContainer'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBD,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  setMeasurementIdFilter,
  setUserId,
  setStatusId,
  setApprovedTransfer,
  setApprovedArshin,
  setApprovedFsa,
  setDateFrom,
  setDateTo,
  setSelectedProtocols,
  setViewVariantMain,
  setSearchQuery as setSearchQueryProtocol,
} from '../../../store/slices/app/views/protocols'

const AllProtocols = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const userRole = useSelector(state => state.headerReducer.role)
  const protocolsData = useSelector(state => state.protocolReducer.protocolsList)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const viewVariant = useSelector(state => state.protocolReducer.viewVariantMain)
  const selectedProtocols = useSelector(state => state.protocolReducer.selectedProtocols)
  const protocolSearchQuery = useSelector(state => state.protocolReducer.searchQuery)
  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)
  const userId = useSelector(state => state.protocolReducer.userId)
  const statusProtocol = useSelector(state => state.protocolReducer.statusId)
  const approvedTransfer = useSelector(state => state.protocolReducer.approvedTransfer)
  const approvedArshin = useSelector(state => state.protocolReducer.approvedArshin)
  const approvedFsa = useSelector(state => state.protocolReducer.approvedFsa)
  const dateFrom = useSelector(state => state.protocolReducer.dateFrom)
  const dateTo = useSelector(state => state.protocolReducer.dateTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (statusProtocol) filtersCount++
    if (approvedTransfer) filtersCount++
    if (approvedArshin) filtersCount++
    if (approvedFsa) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (
      !measurementIdFilter &&
      !userId &&
      !statusProtocol &&
      !approvedTransfer &&
      !approvedArshin &&
      !approvedFsa &&
      !dateFrom &&
      !dateTo
    )
      filtersCount = 0
  }, [
    measurementIdFilter,
    userId,
    statusProtocol,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  const countArchive = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (!measurementIdFilter && !userId && !dateFrom && !dateTo) filtersCount = 0

    return filtersCount
  }, [measurementIdFilter, userId, dateFrom, dateTo])

  const resetActions = [
    setMeasurementIdFilter,
    setUserId,
    setStatusId,
    setApprovedTransfer,
    setApprovedArshin,
    setApprovedFsa,
    setDateFrom,
    setDateTo,
  ]

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const handleVerifiedAtChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(null))
    }
  }

  const handleExpiredAtChange = date => {
    if (date) {
      dispatch(setDateTo(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateTo(null))
    }
  }

  const changeMeasurementId = param => {
    dispatch(setMeasurementIdFilter(param))
  }

  const changeUserId = param => {
    dispatch(setUserId(param))
  }
  const changeApprovedStatus = event => {
    dispatch(setStatusId(event.target.value))
  }

  const changeApprovedTransfer = event => {
    dispatch(setApprovedTransfer(event.target.value))
  }

  const changeApprovedArshin = event => {
    dispatch(setApprovedArshin(event.target.value))
  }
  const changeApprovedFsa = event => {
    dispatch(setApprovedFsa(event.target.value))
  }

  useEffect(() => {
    if (approvedTransfer === 'no') {
      dispatch(setApprovedArshin(''))
    }

    if (approvedTransfer === 'no' || approvedArshin === 'not-published') {
      dispatch(setApprovedFsa(''))
    }
  }, [approvedTransfer, approvedArshin, dispatch])

  const changeViewVariant = param => {
    handleResetFilter()
    dispatch(setViewVariantMain(param))
  }

  const filteredProtocolsData = useMemo(() => {
    return protocolsData
      ? protocolsData.filter(item => {
          if (viewVariant === 0) {
            return !item.archived_at
          } else if (viewVariant === 1) {
            return item.archived_at
          }
          return true
        })
      : []
  }, [protocolsData])

  const noRowsText =
    viewVariant === 0
      ? 'Протоколы не созданы или перемещены в архив'
      : 'Архивные протоколы не найдены'

  const changeSearchQuery = param => {
    dispatch(setSearchQueryProtocol(param))
  }
  const handleShowClick = param => {
    isMobile && navigate(`/metriva/protocols/${param.row.id}`)
  }
  const handleEditClick = protocolId => {
    navigate(`/metriva/protocols/${protocolId}/edit`)
  }
  const handleCopyClick = protocolId => {
    navigate(`/metriva/protocols/${protocolId}/copy`)
  }

  const handleDelete = async protocolId => {
    const { status, data: deleteProtocol } = await R.deleteProtocol(protocolId)
    if (status === 200) {
      dispatch(refreshProtocols())

      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Протокол удален'))
      dispatch(setOpen(false))
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(deleteProtocol.message))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  const handleCheckProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status, data: protocolCheckData } = await R.protocolCheck(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно одобрены для передачи в Аршин и ФСА'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(protocolCheckData.message))
    }
  }

  const handleClickOnArchiveBtn = () => {
    const filteredBySelected = filteredProtocolsData.filter(item =>
      selectedProtocols.includes(item.id),
    )

    const allThreeConditionsMet = filteredBySelected.every(
      item =>
        item.status === 'sent' &&
        item.status_arshin === 'published' &&
        item.status_fsa === 'published',
    )

    const someConditionsNotMet = filteredBySelected.some(
      item =>
        item.status !== 'sent' ||
        item.status_arshin !== 'published' ||
        item.status_fsa !== 'published',
    )

    const validProtocols = filteredBySelected.filter(
      item =>
        item.status === 'sent' &&
        item.status_arshin === 'published' &&
        item.status_fsa === 'published',
    )

    const updatedProtocols = validProtocols.map(item => item.id)

    let title
    let message
    let confirmAction

    if (allThreeConditionsMet) {
      title = 'Архивация протокола'
      message =
        'Вы действительно хотите перенести выбранные протоколы в архив?\nАрхивная версия будет доступна только в режиме просмотра.'
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else if (someConditionsNotMet && validProtocols.length > 0) {
      title = 'Архивация приостановлена'
      message =
        'Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС «Аршин» и ФСА.\nВы можете продолжить, исключив данные протоколы из списка архивируемых, либо возобновить процесс позднее.'
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else {
      title = 'Архивация приостановлена'
      message =
        'Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС «Аршин» и ФСА.'
      confirmAction = () => dispatch(setOpen(false))
    }

    dispatch(setOpen(true))
    dispatch(setTitle(title))
    dispatch(setChildren(message))
    dispatch(setBA(validProtocols.length > 0 ? 'Архивировать' : 'Закрыть'))
    dispatch(setConfirmAction(confirmAction))
    if (validProtocols.length > 0) {
      dispatch(showBD(true))
    } else {
      dispatch(showBD(false))
    }
  }

  const handleArchiveProtocol = async updatedProtocols => {
    const data = {
      ids: updatedProtocols,
    }

    const { status, data: protocolArchiveData } = await R.protocolArchive(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно архивированы.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(protocolArchiveData.message))
    }
  }

  const handleUnArchiveProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status, data: protocolUnArchiveData } = await R.protocolUnArchive(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно восстановлены из архива.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(protocolUnArchiveData.message))
    }
  }

  const handlePdf = async () => {
    const data = {
      ids: selectedProtocols,
    }

    try {
      const { status, data: fileData } = await R.protocolPdf(data)

      if (status === 200) {
        let blob
        let fileName

        if (selectedProtocols.length === 1) {
          blob = new Blob([fileData], { type: 'application/pdf' })
          fileName = `protocol_${selectedProtocols[0]}.pdf`
        } else {
          blob = new Blob([fileData], { type: 'application/zip' })
          fileName = `protocols_${selectedProtocols.join('-')}.zip`
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage('PDF успешно скачан(ы).'))
        dispatch(setType('success'))
        dispatch(setOpenAlert(true))
      } else {
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage(fileData.message))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  useEffect(() => {
    dispatch(setState({}))
  }, [])
  useEffect(() => {
    dispatch(setName('Созданные протоколы'))
  }, [])

  return (
    <main className='main' style={{ display: 'flex', flexDirection: 'column' }}>
      {userRole === 'super' && (
        <div style={{ width: isMobile ? '605px' : '100%', marginBottom: '10px' }}>
          <FilterCompany />
        </div>
      )}

      {(userRole === 'super' || userRole === 'admin') && (
        <BasicTabs
          viewVariant={viewVariant}
          callback={changeViewVariant}
          tabs={[{ label: 'Актуальные' }, { label: 'Архивные' }]}
        />
      )}

      {viewVariant === 0 ? (
        <React.Fragment>
          <RightSideContainer blockTitle={'Фильтры'}>
            <div className='right-side-container'>
              <div className='right-side-container-block'>
                <span style={{ fontWeight: 600 }}>Область измерения</span>
                <div style={{ width: '100%' }}>
                  <FilterMeasurement
                    measurementId={measurementIdFilter}
                    setMeasurementId={changeMeasurementId}
                    isRequired={false}
                  />
                </div>
              </div>

              {userRole !== 'metrologist' && (
                <div className='right-side-container-block'>
                  <span style={{ fontWeight: 600 }}>Поверители</span>
                  <div style={{ width: '100%' }}>
                    <FilterUsers userId={userId} setUserId={changeUserId} />
                  </div>
                </div>
              )}

              <div className='right-side-container-block'>
                <span style={{ fontWeight: 600 }}>Заключение поверки</span>
                <div style={{ width: '100%' }}>
                  <RadioButton
                    value={statusProtocol}
                    onChange={changeApprovedStatus}
                    trueLabel={'Пригоден'}
                    falseLabel={'Непригоден'}
                    trueValue={true}
                    falseValue={false}
                  />
                </div>
              </div>

              <div className='right-side-container-block'>
                <span style={{ fontWeight: 600 }}>Одобрен к передаче</span>
                <div style={{ width: '100%' }}>
                  <RadioButton
                    value={approvedTransfer}
                    onChange={changeApprovedTransfer}
                    trueLabel={'Да'}
                    falseLabel={'Нет'}
                    trueValue={'yes'}
                    falseValue={'no'}
                  />
                </div>
              </div>

              {approvedTransfer === 'yes' && (
                <Collapse in={approvedTransfer === 'yes'}>
                  <div className='right-side-container-block'>
                    <span style={{ fontWeight: 600 }}>Передан в Аршин</span>
                    <RadioButton
                      value={approvedArshin}
                      onChange={changeApprovedArshin}
                      trueLabel={'Да'}
                      falseLabel={'Нет'}
                      trueValue={'published'}
                      falseValue={'not-published'}
                    />
                  </div>
                </Collapse>
              )}

              {approvedTransfer === 'yes' && approvedArshin === 'published' && (
                <Collapse in={approvedTransfer === 'yes' && approvedArshin === 'published'}>
                  <div className='right-side-container-block'>
                    <span style={{ fontWeight: 600 }}>Передан в ФСА</span>
                    <RadioButton
                      value={approvedFsa}
                      onChange={changeApprovedFsa}
                      trueLabel={'Да'}
                      falseLabel={'Нет'}
                      trueValue={'published'}
                      falseValue={'not-published'}
                    />
                  </div>
                </Collapse>
              )}

              <div className='right-side-container-block'>
                <span style={{ fontWeight: 600 }}>Дата поверки</span>
                <DatePicker selectedDate={dateFrom} dateChange={handleVerifiedAtChange} />
                <DatePicker selectedDate={dateTo} dateChange={handleExpiredAtChange} />
              </div>

              <Button
                onClick={handleResetFilter}
                label='Сбросить все значения'
                color='secondary'
                fullWidth
              />
            </div>
          </RightSideContainer>

          <div style={{ display: 'flex', gap: '14px', marginTop: '12px' }}>
            <SearchQuery searchQuery={protocolSearchQuery} setSearchQuery={changeSearchQuery} />
            <Csv />
            <div style={{ position: 'relative' }}>
              <Button
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterIcon />}
                label='Фильтры'
                color='secondary'
              />
              <span
                style={{
                  display: 'block',
                  position: 'absolute',
                  width: '22px',
                  height: '22px',
                  borderRadius: '50%',
                  backgroundColor: '#1976d2',
                  color: 'white',
                  fontSize: '11px',
                  lineHeight: '21px',
                  textAlign: 'center',
                  top: 0,
                  left: '100%',
                  marginLeft: '-18px',
                  marginTop: '-4px',
                  transition: 'all 300ms',
                  opacity: count > 0 ? 1 : 0,
                }}
              >
                {count}
              </span>
            </div>
          </div>

          {selectedProtocols && selectedProtocols.length > 0 && (
            <Collapse in={selectedProtocols && selectedProtocols.length > 0}>
              <div>
                <Button
                  onClick={handlePdf}
                  label='Скачать PDF'
                  color='text'
                  startIcon={<PdfIcon color={theme.palette.text.dark} />}
                />
                {userRole !== 'metrologist' && (
                  <Button
                    onClick={handleCheckProtocol}
                    label='Передать в Аршин и ФСА'
                    color='text'
                    startIcon={<ExportIcon color={theme.palette.text.dark} />}
                  />
                )}
                {(userRole === 'super' || userRole === 'admin') && (
                  <Button
                    onClick={handleClickOnArchiveBtn}
                    label='Архивировать'
                    color='text'
                    startIcon={<ArchiveIcon color={theme.palette.text.dark} />}
                  />
                )}
              </div>
            </Collapse>
          )}

          <Table
            colData={[
              {
                field: 'numId',
                headerName: '№',
              },
              {
                field: 'verificationDate',
                headerName: 'Дата поверки',
              },
              {
                field: 'totalAccepted',
                headerName: 'Заключение',
              },
              userRole === 'metrologist'
                ? {
                    field: 'measurement',
                    headerName: 'Область измерения',
                  }
                : {
                    field: 'createdBy',
                    headerName: (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Поверитель
                        <Tooltip
                          title={
                            'Фактический поверитель - пользователь который создал протокол в системе'
                          }
                          style={{ marginLeft: '7px' }}
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                    ),
                  },

              {
                field: 'status',
                type: 'boolean',
                headerName: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Одобрен к передаче
                    <Tooltip
                      title={
                        isTrusted
                          ? 'Для одобрения передачи: выберите необходимые протоколы в общем списке и нажмите кнопку "Передать в Аршин и ФСА" или предоставьте разрешение в процессе создания или редактирования протокола'
                          : ' Дождитесь когда передача протоколов во ФГИС «Аршин» будет одобрена руководителем или администратором'
                      }
                      style={{ marginLeft: '7px' }}
                    >
                      <InfoCircle />
                    </Tooltip>
                  </div>
                ),

                // renderCell: params => {
                //   const { status } = params.row

                //   if (status === 'sent') {
                //     return (
                //       <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                //         <CheckCircleIcon />
                //       </div>
                //     )
                //   } else {
                //     return (
                //       <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                //         <ExitIcon />
                //       </div>
                //     )
                //   }
                // },
              },

              {
                field: 'statusArshin',
                headerName: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Передача в Аршин
                    <Tooltip title={'Информация обновляется'} style={{ marginLeft: '7px' }}>
                      <InfoCircle />
                    </Tooltip>
                  </div>
                ),

                renderCell: params => {
                  const { statusArshin } = params.row

                  if (statusArshin === 'not-sent') {
                    return <p style={{ color: '#E74953' }}>Не начата</p>
                  } else if (statusArshin === 'sent') {
                    return <p style={{ color: '#E57004' }}>В процессе</p>
                  } else if (statusArshin === 'published') {
                    return <p style={{ color: '#00A37C' }}>Завершена</p>
                  }
                },
              },
              {
                field: 'statusFsa',
                headerName: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Передача в ФСА
                    <Tooltip
                      title='Передача начнётся автоматически после получения данных о номере свидетельства или извещения из ФГИС «Аршин»'
                      placement='top'
                      style={{ marginLeft: '7px' }}
                    >
                      <InfoCircle />
                    </Tooltip>
                  </div>
                ),

                renderCell: params => {
                  const { statusFsa } = params.row

                  if (statusFsa === 'not-sent') {
                    return <p style={{ color: '#E74953' }}>Не начата</p>
                  } else if (statusFsa === 'sent') {
                    return <p style={{ color: '#E57004' }}>В процессе</p>
                  } else if (statusFsa === 'published') {
                    return <p style={{ color: '#00A37C' }}>Завершена</p>
                  }
                },
              },
              {
                field: 'certificate',
                headerName: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    № свидетельства или извещения
                    <Tooltip title={'Приходит из ФГИС «Аршин»'} style={{ marginLeft: '7px' }}>
                      <InfoCircle />
                    </Tooltip>
                  </div>
                ),
              },
              {
                field: 'arshinId',
                headerName: 'Публикация данных о поверке СИ',
                renderCell: params => {
                  const { arshinId } = params.row

                  if (arshinId) {
                    return (
                      <a
                        href={`https://fgis.gost.ru/fundmetrology/cm/results/1-${arshinId}`}
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                        onClick={event => event.stopPropagation()}
                        rel='noreferrer'
                      >
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.secondary}
                          sx={{
                            cursor: 'pointer',
                            borderBottom: `1px solid ${theme.palette.text.secondary}`,
                            fontWeight: '500 !important',
                          }}
                        >
                          Ссылка на ФГИС Аршин
                        </Typography>
                      </a>
                    )
                  } else {
                    return (
                      <Typography
                        variant='subtitle2'
                        color={'#899298'}
                        sx={{ fontWeight: '500 !important' }}
                      >
                        Ссылка на ФГИС Аршин
                      </Typography>
                    )
                  }
                },
              },
              {
                field: 'actions',
                type: 'actions',
                headerName: 'Действия',

                getActions: item => {
                  const localItem = item

                  return [
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '16px',
                      }}
                    >
                      {localItem.row.originalCase !== 'Комплект СП не доступен' ? (
                        <Tooltip title='Копировать протокол'>
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCopyClick(localItem.id)}
                          >
                            <CopyIcon />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Копирование протокола недоступно, используемый комплект СП был удалён или изменён'>
                          <CopyIcon color='#BDC1C4' />
                        </Tooltip>
                      )}
                      {localItem.row.status !== 'sent' ? (
                        <Tooltip title='Редактировать протокол'>
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEditClick(localItem.id)}
                          >
                            <PencilIcon />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Редактирование недоступно, протокол уже одобрен к передаче в Аршин и ФСА'>
                          <PencilIcon color='#BDC1C4' />
                        </Tooltip>
                      )}
                      {userRole === 'super' || userRole === 'admin' ? (
                        localItem.row.status !== 'sent' ? (
                          <Tooltip title='Удалить протокол'>
                            <div
                              onClick={() => {
                                dispatch(setOpen(true))
                                dispatch(setTitle('Вы действительно хотите удалить протокол?'))
                                dispatch(setChildren('Отменить действие будет уже невозможно.'))
                                dispatch(setBA('Удалить'))
                                dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                              }}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <DeleteIcon />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Удаление недоступно, протокол уже одобрен к передаче в Аршин и ФСА'>
                            <DeleteIcon color='#BDC1C4' />
                          </Tooltip>
                        )
                      ) : null}
                    </div>,
                  ]
                },
              },
            ]}
            rowData={filteredProtocolsData.map(item => ({
              id: item.id,
              numId: item.number ?? '-',
              verificationDate: moment(item.verification_date).format('DD-MM-YYYY'),
              measurement: item.measurement?.name ?? '-',
              createdBy: `${item.createdBy?.surname ?? ''} ${item.createdBy?.name ?? ''}`,
              totalAccepted: item.total_accepted ? 'Пригоден' : 'Непригоден',
              status: item.status === 'sent' ?? '-',
              statusArshin: item.status_arshin ?? '-',
              statusFsa: item.status_fsa ?? '-',
              certificate: item.certificate ?? '-',
              arshinId: item.arshin_id,
              originalCase: item.originalCase
                ? item.originalCase
                : 'Эталоны комплекта были изменены',
            }))}
            rowCallback={handleShowClick}
            rowSelectionCallback={array => {
              dispatch(setSelectedProtocols(array))
            }}
            noRowsText={noRowsText}
            hasCheckboxSelection={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RightSideContainer blockTitle={'Фильтры'}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Typography variant='subtitle1'>Область измерения</Typography>
                <FilterMeasurement
                  measurementId={measurementIdFilter}
                  setMeasurementId={changeMeasurementId}
                  isRequired={false}
                />
              </div>

              {userRole !== 'metrologist' && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Typography variant='subtitle1'>Поверители</Typography>
                  <FilterUsers userId={userId} setUserId={changeUserId} />
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle1'>Заключение поверки</Typography>
                <RadioButton
                  value={statusProtocol}
                  onChange={changeApprovedStatus}
                  trueLabel={'Пригоден'}
                  falseLabel={'Непригоден'}
                  trueValue={true}
                  falseValue={false}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Typography variant='subtitle1'>Дата поверки</Typography>
                <DatePicker selectedDate={dateFrom} dateChange={handleVerifiedAtChange} />

                <DatePicker selectedDate={dateTo} dateChange={handleExpiredAtChange} />
              </div>

              <Button
                onClick={handleResetFilter}
                label='Сбросить все значения'
                color='secondary'
                fullWidth
              />
            </div>
          </RightSideContainer>

          <div style={{ display: 'flex', gap: '16px', marginTop: '12px' }}>
            <SearchQuery searchQuery={protocolSearchQuery} setSearchQuery={changeSearchQuery} />
            <Csv />
            <div style={{ position: 'relative' }}>
              <Button
                onClick={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                startIcon={<FilterIcon />}
                label='Фильтры'
                color='secondary'
              />
              <span
                style={{
                  display: 'block',
                  position: 'absolute',
                  width: '22px',
                  height: '22px',
                  borderRadius: '50%',
                  backgroundColor: '#1976d2',
                  color: 'white',
                  fontSize: '11px',
                  lineHeight: '21px',
                  textAlign: 'center',
                  top: 0,
                  left: '100%',
                  marginLeft: '-18px',
                  marginTop: '-4px',
                  transition: 'all 300ms',
                  opacity: countArchive > 0 ? 1 : 0,
                }}
              >
                {countArchive}
              </span>
            </div>
          </div>

          {selectedProtocols && selectedProtocols.length > 0 && (
            <Collapse in={selectedProtocols && selectedProtocols.length > 0}>
              <div>
                <Button
                  onClick={handlePdf}
                  label='Скачать PDF'
                  color='text'
                  startIcon={<PdfIcon color={theme.palette.text.dark} />}
                />

                {(userRole === 'super' || userRole === 'admin') && (
                  <Button
                    onClick={() => {
                      dispatch(setOpen(true))

                      dispatch(setTitle('Вы действительно хотите востановить выбранные протоколы?'))
                      dispatch(
                        setChildren(
                          "После восстановления, выбранные протоколы будут перенесены во вкладку 'Актуальные'.",
                        ),
                      )
                      dispatch(setBA('Восстановить'))
                      dispatch(setConfirmAction(() => handleUnArchiveProtocol()))
                    }}
                    label='Восстановить'
                    color='text'
                    startIcon={<RestoreIcon color={theme.palette.text.dark} />}
                  />
                )}
              </div>
            </Collapse>
          )}

          <Table
            colData={[
              {
                field: 'numId',
                headerName: '№',
              },
              {
                field: 'verificationDate',
                headerName: 'Дата поверки',
              },
              {
                field: 'totalAccepted',
                headerName: 'Заключение',
              },
              userRole === 'metrologist'
                ? {
                    field: 'measurement',
                    headerName: 'Область измерения',
                  }
                : {
                    field: 'createdBy',
                    headerName: (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Поверитель
                        <Tooltip
                          title={
                            'Фактический поверитель - пользователь который создал протокол в системе'
                          }
                          style={{ marginLeft: '7px' }}
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                    ),
                  },

              {
                field: 'arshinId',
                headerName: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    № свидетельства или извещения
                    <Tooltip title={'Приходит из ФГИС «Аршин»'} style={{ marginLeft: '7px' }}>
                      <InfoCircle />
                    </Tooltip>
                  </div>
                ),
              },
            ]}
            rowData={filteredProtocolsData.map(item => ({
              id: item.id,
              numId: item.number ?? '-',
              verificationDate: moment(item.verification_date).format('DD-MM-YYYY'),
              measurement: item.measurement?.name ?? '-',
              createdBy: `${item.createdBy?.surname ?? ''} ${item.createdBy?.name ?? ''}`,
              totalAccepted: item.total_accepted ? 'Пригоден' : 'Непригоден',
              arshinId: item.certificate ?? '-',
            }))}
            rowCallback={handleShowClick}
            rowSelectionCallback={array => {
              dispatch(setSelectedProtocols(array))
            }}
            noRowsText={noRowsText}
            hasCheckboxSelection={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </main>
  )
}

export default AllProtocols
