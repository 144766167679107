// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import RightSideContainer from '../../../LocalElements/RightSideContainer/RightSideContainer'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import { Wrapper, FrameFilter } from '../../style'
import RadioButton from '../../../../../ui/radio-button/index'
import Button from '../../../../../ui/button/index'
import {
  changeKitPassportsFilter,
  resetKitPassportsFilter,
} from '../../../../../store/slices/app/views/journals'
import ResponsibleName from '../../../LocalElements/SelectResponsibleName/ResponsibleName'

const Filter = () => {
  const dispatch = useDispatch()

  const systemExistingStatus = useSelector(
    state => state.journalReducer.kitPassportsFilter.systemExistingStatus
  )
  const responsiblePassportName = useSelector(
    state => state.journalReducer.kitPassportsFilter.responsiblePassportName
  )
  const formedDateFrom = useSelector(
    state => state.journalReducer.kitPassportsFilter.formedDateFrom
  )
  const formedDateTo = useSelector(state => state.journalReducer.kitPassportsFilter.formedDateTo)

  const handleResetFilter = () => {
    dispatch(resetKitPassportsFilter())
  }

  const changeSystemExistingStatus = event => {
    dispatch(changeKitPassportsFilter({ value: 'systemExistingStatus', label: event.target.value }))
  }

  const changeResponsiblePassportName = param => {
    dispatch(changeKitPassportsFilter({ value: 'responsiblePassportName', label: param }))
  }

  const handleFormedDateFromChange = date => {
    if (date) {
      dispatch(
        changeKitPassportsFilter({
          value: 'formedDateFrom',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitPassportsFilter({ value: 'formedDateFrom', label: null }))
    }
  }
  const handleFormedDateToChange = date => {
    if (date) {
      dispatch(
        changeKitPassportsFilter({
          value: 'formedDateTo',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changeKitPassportsFilter({ value: 'formedDateTo', label: '' }))
    }
  }

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  return (
    <RightSideContainer blockTitle={'Фильтры'} containerWidth={400}>
      <Wrapper>
        <FrameFilter>
          <Typography variant='subtitle1'>ФИО ответственного</Typography>
          <ResponsibleName
            responsiblePassportName={responsiblePassportName}
            setResponsiblePassportName={changeResponsiblePassportName}
          />
        </FrameFilter>

        <FrameFilter>
          <Typography variant='subtitle1'>Дата составления</Typography>
          <DatePicker selectedDate={formedDateFrom} dateChange={handleFormedDateFromChange} />

          <DatePicker selectedDate={formedDateTo} dateChange={handleFormedDateToChange} />
        </FrameFilter>

        <FrameFilter>
          <Typography variant='subtitle1'>СП добавлен в систему</Typography>
          <RadioButton
            value={systemExistingStatus}
            onChange={changeSystemExistingStatus}
            trueLabel={'Да'}
            falseLabel={'Нет'}
            trueValue={'exist'}
            falseValue={'not-exist'}
          />
        </FrameFilter>
      </Wrapper>

      <Button
        onClick={handleResetFilter}
        label='Сбросить все значения'
        color='secondary'
        fullWidth
      />
    </RightSideContainer>
  )
}

export default Filter
